import { auth, db } from '../firebase';
import { doc, addDoc, setDoc, getDoc, getDocs, updateDoc, collection, serverTimestamp, orderBy, query, limit, where, getCountFromServer, startAfter } from "firebase/firestore";
import axios from "axios";
import moment from 'moment';
import { getUser } from './userServices';
import * as constants from "../constants";

// this function retrieves the projects with given status from Firestore
export async function getAllProjects(status) {
    let results = [];
    try {
        const allProjectsQuery = query(collection(db, "projects"), where("status", "==", status), orderBy("updatedOn", "desc"));
        const projectsDocsSnapshot = await getDocs(allProjectsQuery);
        projectsDocsSnapshot.forEach(doc => {
            results.push({ ...doc.data(), id: doc.id });
        })
    } catch (e) {
        console.error("Error getting all projects data: ", e);
    }
    return results;
}

// this function returns 
export async function getSupplierQuotes(supplierEmail, projectId) {
    let results = [];
    try {
        const quotesRef = collection(db, "quotes");
        const projectRef = doc(db, "projects", projectId);
        const q = query(
            quotesRef,
            where("createdBy", "==", supplierEmail),
            where("projectRef", "==", projectRef),
            orderBy("updatedOn", "desc"));
        const quotesQuerySnapshot = await getDocs(q);

        for (const quoteDoc of quotesQuerySnapshot.docs) {
            results.push(quoteDoc.data());
        }
    } catch (e) {
        console.log("Error retrieving quotes from Firestore for the given project by the current user: ", e)
    }
    return results;
}

export async function getSupplierAdjustedQuotes(supplierEmail, projectId) {
    let results = [];
    try {
        const quotesRef = collection(db, "quotes");
        const projectRef = doc(db, "projects", projectId);
        const q = query(
            quotesRef,
            where("createdBy", "==", supplierEmail),
            where("projectRef", "==", projectRef),
            where("status", "==", constants.STATUS_QUOTE_ADJUSTED),
            orderBy("updatedOn", "desc"));
        const quotesQuerySnapshot = await getDocs(q);

        for (const quoteDoc of quotesQuerySnapshot.docs) {
            results.push({ ...quoteDoc.data(), id: quoteDoc.id });
        }
    } catch (e) {
        console.log("Error retrieving adjusted quotes for the project by the current user: ", e)
    }
    return results;
}

export async function getAllAdjustedQuotes(projectId) {
    let results = [];
    try {
        const quotesRef = collection(db, "quotes");
        const projectRef = doc(db, "projects", projectId);
        const q = query(
            quotesRef,
            where("projectRef", "==", projectRef),
            where("status", "==", constants.STATUS_QUOTE_ADJUSTED),
            orderBy("updatedOn", "desc"));
        const quotesQuerySnapshot = await getDocs(q);

        for (const quoteDoc of quotesQuerySnapshot.docs) {
            results.push({ ...quoteDoc.data(), id: quoteDoc.id });
        }
    } catch (e) {
        console.log("Error retrieving all adjusted quotes for the project: ", e)
    }
    return results;
}

// this function retrieves the lastest exam data for the specified number of users from Firestore
/*export async function getProjects(numberOfProjects, lastProjectIndex) {
    let results = [];
    try {
        var tempProjectsCollection = [];
        var pageQuery;

        if (lastProjectIndex <= 0) {
            pageQuery = query(collection(db, "projects"), orderBy("updatedOn", "desc"), limit(numberOfProjects));
        } else {
            // Get the last case index doc
            let upToLastPrjIndexQuery = query(collection(db, "projects"), orderBy("updatedOn", "desc"), limit(lastProjectIndex));
            let upToLastPrjIndexSnapshot = await getDocs(upToLastPrjIndexQuery);
            const lastProject = upToLastPrjIndexSnapshot.docs[upToLastPrjIndexSnapshot.docs.length-1];

            // Get the next page docs after the last case index doc
            pageQuery = query(collection(db, "projects"), orderBy("updatedOn", "desc"), startAfter(lastProject), limit(numberOfProjects));
        }
        const casesDocsSnapshot = await getDocs(pageQuery);        
        casesDocsSnapshot.forEach(caseDoc => {
            tempProjectsCollection.push(caseDoc);
        })

        for (const projectDoc of tempProjectsCollection) {
            const userData = await getUser(projectDoc.id);

            const examsColRef = collection(db, "cases", projectDoc.id, "exams");
            const latestExamQuery = query(examsColRef, orderBy("examDate", "desc"), limit(1));
            const latestExamSnapshot = await getDocs(latestExamQuery);
            
            const quotesColRef = collection(db, "cases", projectDoc.id, "exams", latestExamSnapshot.docs[0].id, "quotes");
            const quotesDocsSnapshot = await getDocs(quotesColRef);

            var tempQuotesData = [];
            quotesDocsSnapshot?.forEach(quoteDoc => {
                tempQuotesData.push(quoteDoc.data());
            })
            
            const mergedObj = { ...projectDoc?.data() }//, quotes: tempQuotesData }
            console.log("Retrieved latest project successfully");
            //console.log("mergedObj: ", mergedObj);
            results.push(mergedObj);
        }
    } catch (e) {
        console.error("Error getting active projects data: ", e);
    } 
    return results;
}*/

// this function adds new quote record for scenarios such as editing quote during adjustment iteration steps
export async function createQuote(projectId, supplierEmail, isExactDesign, productionTimeline, shippingTimeline, shippingLocation,
    designTechDrawingFiles, componentPictureFiles,
    moq, unitCost, shippingCost, nonDecoSampleCost, decoSampleCost, estimatedQuote, expiresIn, comments, status) {
    try {
        const quotesRef = collection(db, "quotes");
        const projectRef = doc(db, "projects", projectId);
        const supplierUserData = await getUser(supplierEmail);

        await addDoc(quotesRef, {
            status: status,
            projectRef: projectRef,
            isExactDesign: isExactDesign,
            productionTimeline: productionTimeline,
            shippingTimeline: shippingTimeline,
            shippingLocation: shippingLocation,
            moq: moq,
            unitCost: unitCost,
            shippingCost: shippingCost,
            nonDecoSampleCost: nonDecoSampleCost,
            decoSampleCost: decoSampleCost,
            estimatedQuote: estimatedQuote,
            expiresIn: expiresIn,
            comments: comments,
            supplierCompany: supplierUserData?.company,
            supplierLocation: supplierUserData?.location,
            createdBy: auth?.currentUser?.email,
            createdOn: serverTimestamp(),
            updatedBy: auth?.currentUser?.email,
            updatedOn: serverTimestamp(),
        }).then(async (docRef) => {
            console.log("Adding a new quote in Firestore successful");
            await addQuoteFiles(docRef?.path, designTechDrawingFiles, componentPictureFiles);
        }).catch(err => {
            console.log(err);
        });
    } catch (e) {
        console.error("Error adding a quote to Firestore: ", e);
    }
}

export async function submitQuote(projectId, supplierEmail, isExactDesign, productionTimeline, shippingTimeline, shippingLocation,
    designTechDrawingFiles, componentPictureFiles,
    moq, unitCost, shippingCost, nonDecoSampleCost, decoSampleCost, estimatedQuote, expiresIn, comments, status) {
    try {
        console.log("designTechDrawingFiles", designTechDrawingFiles);
        console.log("componentPictureFiles", componentPictureFiles);
        const quotesRef = collection(db, "quotes");
        const projectRef = doc(db, "projects", projectId);
        const supplierUserData = await getUser(supplierEmail);

        const quotesQuery = query(quotesRef, where("projectRef", "==", projectRef), where("createdBy", "==", supplierEmail));
        const existingQuotesSnapshot = await getDocs(quotesQuery);

        if (existingQuotesSnapshot?.docs?.length > 0) {
            await setDoc(existingQuotesSnapshot?.docs[0]?.ref, {
                status: status,
                projectRef: projectRef,
                isExactDesign: isExactDesign,
                productionTimeline: productionTimeline,
                shippingTimeline: shippingTimeline,
                shippingLocation: shippingLocation,
                moq: moq,
                unitCost: unitCost,
                shippingCost: shippingCost,
                nonDecoSampleCost: nonDecoSampleCost,
                decoSampleCost: decoSampleCost,
                estimatedQuote: estimatedQuote,
                expiresIn: expiresIn,
                comments: comments,
                supplierCompany: supplierUserData?.company,
                supplierLocation: supplierUserData?.location,
                updatedBy: auth?.currentUser?.email,
                updatedOn: serverTimestamp(),
            }, {
                merge: true
            }).then(async (docRef) => {
                console.log("Updating an existing quote in Firestore successful");
                await addQuoteFiles(existingQuotesSnapshot?.docs[0]?.ref?.path, designTechDrawingFiles, componentPictureFiles);
            }).catch(err => {
                console.log(err);
            });
        } else {
            await addDoc(quotesRef, {
                status: status,
                projectRef: projectRef,
                isExactDesign: isExactDesign,
                productionTimeline: productionTimeline,
                shippingTimeline: shippingTimeline,
                shippingLocation: shippingLocation,
                moq: moq,
                unitCost: unitCost,
                shippingCost: shippingCost,
                nonDecoSampleCost: nonDecoSampleCost,
                decoSampleCost: decoSampleCost,
                estimatedQuote: estimatedQuote,
                expiresIn: expiresIn,
                comments: comments,
                supplierCompany: supplierUserData?.company,
                supplierLocation: supplierUserData?.location,
                createdBy: auth?.currentUser?.email,
                createdOn: serverTimestamp(),
                updatedBy: auth?.currentUser?.email,
                updatedOn: serverTimestamp(),
            }).then(async (docRef) => {
                console.log("Adding a new quote in Firestore successful");
                await addQuoteFiles(docRef?.path, designTechDrawingFiles, componentPictureFiles);
            }).catch(err => {
                console.log(err);
            });
        }
    } catch (e) {
        console.error("Error adding a quote to Firestore: ", e);
    }
}

// this function adds given type messages to a given project in Firestore
export async function addProjectMessages(projectId, type, message) {
    try {
        const projectRef = doc(db, "projects", projectId);

        // get user role
        const user = await getUser(auth?.currentUser?.email);

        await addDoc(collection(db, "messages"), {
            msg: message,
            projectRef: projectRef,
            type: type,
            role: user?.role,
            firstName: user?.firstName,
            lastName: user?.lastName,
            createdBy: auth?.currentUser?.email,
            createdOn: serverTimestamp(),
        }).then(() => {
            console.log("Adding a message to Firestore successful");
        }).catch(err => {
            console.log(err);
        });

    } catch (e) {
        console.error("Error adding a message to Firestore: ", e);
    }
}

// this function returns all messages for a given project and type
export async function getProjectMessages(projectId, type) {
    let results = [];
    try {
        const projectCommentsRef = collection(db, "messages");
        const projectRef = doc(db, "projects", projectId);
        const q = query(projectCommentsRef, where("projectRef", "==", projectRef), where("type", "==", type), orderBy("createdOn", "desc"));
        const commentsQuerySnapshot = await getDocs(q);

        for (const commentDoc of commentsQuerySnapshot.docs) {
            results.push(commentDoc.data());
        }
    } catch (e) {
        console.log("Error retrieving messages from Firestore for the given projectId(" + projectId + ") and type(" + type + "): ", e);
    }
    return results;
}

// this function adds given type messages to a given quote in Firestore
export async function addQuoteMessages(quoteId, type, message) {
    try {
        const quoteRef = doc(db, "quotes", quoteId);

        // get user role
        const user = await getUser(auth?.currentUser?.email);

        await addDoc(collection(db, "messages"), {
            msg: message,
            quoteRef: quoteRef,
            type: type,
            role: user?.role,
            firstName: user?.firstName,
            lastName: user?.lastName,
            createdBy: auth?.currentUser?.email,
            createdOn: serverTimestamp(),
        }).then(() => {
            console.log("Adding a message to Firestore successful");
        }).catch(err => {
            console.log(err);
        });

    } catch (e) {
        console.error("Error adding a message to Firestore: ", e);
    }
}

// this function returns all messages for a given quote and type
export async function getQuoteMessages(quoteId, type) {
    let results = [];
    try {
        const quoteCommentsRef = collection(db, "messages");
        const quoteRef = doc(db, "quotes", quoteId);
        const q = query(quoteCommentsRef, where("quoteRef", "==", quoteRef), where("type", "==", type), orderBy("createdOn", "desc"));
        const commentsQuerySnapshot = await getDocs(q);

        for (const commentDoc of commentsQuerySnapshot.docs) {
            results.push(commentDoc.data());
        }
    } catch (e) {
        console.log("Error retrieving messages from Firestore for the given quoteId(" + quoteId + ") and type(" + type + "): ", e);
    }
    return results;
}

// this function sends files to express server to upload to Google Cloud Storage.
// also updates reference data of the uploaded files to the doc path provided.
// param: type is either designTechDrawing or componentPicture
async function addQuoteFiles(quoteDocPath, designTechDrawings, componentPictures) {
    try {
        const quoteRef = doc(db, quoteDocPath);

        var designTechDrawingsFormData = new FormData();
        var designTechDrawingFiles = (designTechDrawings != null ? designTechDrawings : []);

        var componentPicturesFormData = new FormData();
        var componentPictureFiles = (componentPictures != null ? componentPictures : []);

        for (let i = 0; i < designTechDrawingFiles.length; i++) {
            designTechDrawingsFormData.append('files', designTechDrawingFiles[i].file); // files[i].file due to filepond sending wrapper object containing file
        }
        designTechDrawingsFormData.append('folder', "quotes/" + quoteRef.id + "/designTechDrawings"); // folder name

        for (let i = 0; i < componentPictureFiles.length; i++) {
            componentPicturesFormData.append('files', componentPictureFiles[i].file); // files[i].file due to filepond sending wrapper object containing file
        }
        componentPicturesFormData.append('folder', "quotes/" + quoteRef.id + "/componentPictures"); // folder name

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }

        /*console.log("formData");
        // Display the key/value pairs
        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }*/

        await axios.post(`${process.env.REACT_APP_SERVER_FILES_UPLOAD_API_ENDPOINT}/`, designTechDrawingsFormData, config)
            .then(async res => {
                console.log("Uploading quote files to GCS successful");
                const fileRefData = res?.data?.data;
                await setDoc(quoteRef, {
                    designTechDrawingFileRefData: fileRefData,
                }, {
                    merge: true,
                }).then(() => {
                    console.log("Updating quote with designTechDrawingFileRefData in Firestore successful");
                }).catch(err => {
                    console.log(err);
                });
            })
            .catch(err => {
                console.log(err.response.data);
            });

        await axios.post(`${process.env.REACT_APP_SERVER_FILES_UPLOAD_API_ENDPOINT}/`, componentPicturesFormData, config)
            .then(async res => {
                console.log("Uploading quote files to GCS successful");
                const fileRefData = res?.data?.data;
                await setDoc(quoteRef, {
                    componentPictureFileRefData: fileRefData,
                }, {
                    merge: true,
                }).then(() => {
                    console.log("Updating quote with componentPictureFileRefData in Firestore successful");
                }).catch(err => {
                    console.log(err);
                });
            })
            .catch(err => {
                console.log(err.response.data);
            });
    } catch (e) {
        console.error("Error uploading quote files to GCS: ", e);
    }
}

export async function getSupplierProjectsQuotesData(supplierEmail) {
    let results = [];
    try {
        const quotesRef = collection(db, "quotes");
        let q;
        if (supplierEmail === "admin") {
            q = query(quotesRef, orderBy("updatedOn", "desc"));
        } else {
            const userData = await getUser(supplierEmail);
            q = query(quotesRef, where("supplierCompany", "==", userData?.company), orderBy("updatedOn", "desc"));
        }
        const quotesQuerySnapshot = await getDocs(q);

        const quotesNonAdjustedArray = quotesQuerySnapshot.docs.filter(qd => qd?.data()?.status !== constants.STATUS_QUOTE_ADJUSTED);
        const quotesAdjustedArray = quotesQuerySnapshot.docs.filter(qd => qd?.data()?.status === constants.STATUS_QUOTE_ADJUSTED);

        for (const quoteDoc of quotesNonAdjustedArray) {
            const projectRef = quoteDoc?.data()?.projectRef;
            const projectSnapshot = await getDoc(projectRef);

            const quoteAdjustedArrayForProj = quotesAdjustedArray?.filter(qd => qd?.data()?.projectRef?.id === projectRef?.id);
            const quoteAdjusted = quoteAdjustedArrayForProj?.length > 0 ? quoteAdjustedArrayForProj[0] : null;

            let mergedObj;

            if (quoteAdjusted !== null) {
                mergedObj = {
                    project: { ...projectSnapshot?.data(), id: projectRef?.id },
                    quote: { ...quoteDoc?.data(), id: quoteDoc?.id },
                    quoteAdjusted: { ...quoteAdjusted?.data(), id: quoteAdjusted?.id }
                };
            } else {
                mergedObj = {
                    project: { ...projectSnapshot?.data(), id: projectRef?.id },
                    quote: { ...quoteDoc?.data(), id: quoteDoc?.id },
                };
            }

            results.push(mergedObj);
        }
    } catch (e) {
        if (supplierEmail === "admin") {
            console.error("Error retrieving all quotes and associated projects from Firestore for admin: ", e);
        } else {
            console.error("Error retrieving all quotes and associated projects from Firestore for the given supplier: ", e);
        }
    }
    return results;
}

export async function updateSupplierProfile(firstName, lastName, phone, company, title, location) {
    try {
        const supplierRef = doc(db, "users", auth?.currentUser?.email); // id is email
        await setDoc(supplierRef, {
            firstName: firstName,
            lastName: lastName,
            email: auth?.currentUser?.email,
            phone: phone,
            company: company,
            title: title,
            location: location,
            isProfileComplete: true,
            updatedBy: auth?.currentUser?.email,
            updatedOn: serverTimestamp(),
        }, {
            merge: true
        }).then(() => {
            console.log("Updating supplier profile in Firestore successful");
        }).catch(err => {
            console.log(err);
        });;

        await updateDoc(supplierRef, {
            "role.supplier": true
        }).then(() => {
            console.log("Setting supplier role in Firestore successful");
        }).catch(err => {
            console.log(err);
        });
    } catch (e) {
        console.error("Error updating supplier profile in Firestore: ", e);
    }
}