import * as constants from "../constants";

export function getLatestStatusLabel(projectData, engagedQuoteStatusHistory) {
    if (projectData?.status === constants.STATUS_PROJECT_DRAFT ||
        projectData?.status === constants.STATUS_PROJECT_INIT_ASSESSMENT ||
        projectData?.status === constants.STATUS_PROJECT_ACCEPTING_QUOTES ||
        projectData?.status === constants.STATUS_PROJECT_CANCELLED ||
        projectData?.status === constants.STATUS_PROJECT_COMPLETE) {
        return projectData?.status;
    } else { // get status from engagedQuoteStatus for projects with Supplier Selected status
        if (engagedQuoteStatusHistory && engagedQuoteStatusHistory?.length > 0) {
            const quoteStatusHistoryObjectArray = engagedQuoteStatusHistory.find((e) => e.projectId === projectData?.id)?.statusHistory;
            
            if (quoteStatusHistoryObjectArray && quoteStatusHistoryObjectArray.length > 0) {
                if (quoteStatusHistoryObjectArray[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUST) {
                    return "Non-deco sample adjustment"
                } else if (quoteStatusHistoryObjectArray[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE) {
                    return "Ready for design review"
                } else if (quoteStatusHistoryObjectArray[0]?.status === constants.STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE) {
                    return "Ready for pre-prod sample"
                } else if (quoteStatusHistoryObjectArray[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUST) {
                    return "Pre-prod sample adjustment"
                } else if (quoteStatusHistoryObjectArray[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_COMPLETE) {
                    return "Ready for purchase order"
                }
                return quoteStatusHistoryObjectArray[0]?.status;
            } else { // No quote history found
                return "Ready for non-deco sample";;
            }
        } else {
            return '... Loading';
        }
    }
}