import styles from '../styles/styles.module.css';
import { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Stack, Button, Accordion, Toast, ToastContainer, Image, Alert, Form, Modal } from 'react-bootstrap';
import { setEngagedQuoteStatus, getEngagedQuoteStatus } from "../services/buyerServices";
import Messages from './Messages';
import * as constants from "../constants";
import iconMessage from '../images/icons/communication.png';
import ProgressBarSample from './ProgressBarSample';
import { FormSampleCheckout } from './FormSampleCheckout';
import { StepProgress } from 'react-stepz';
import iconCheckGreen from '../images/icons/checkGreen.png';
import iconInstruction from '../images/icons/instruction.png';

export default function StepBuyerSamplePreProduction(props) {
    const projectData = props?.projectData;
    const quoteData = props?.quoteData;

    const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState(null);
    const [currentStepSample, setCurrentStepSample] = useState(-1);
    const [stateChanged, setStateChanged] = useState(false);

    const[trackingNumber, setTrackingNumber] = useState();

    const requestBtnRef = useRef();
    const deliveredBtnRef = useRef();
    const requestAdjBtnRef = useRef();
    const adjDeliveredBtnRef = useRef();
    const completeBtnRef = useRef();

    const [sampleProgressAlertMsg, setSampleProgressAlertMsg] = useState();

    // Toast 
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    
    const [toastSuccessShow, setToastSuccessShow] = useState(false);
    const [toastErrorShow, setToastErrorShow] = useState(false);

    // Modal
    const [showSampleRequestModal, setShowSampleRequestModal] = useState(false);
    const [showSampleCompletionModal, setShowSampleCompletionModal] = useState(false);

    useEffect(() => {
        async function engagedQuoteFetchData() {
            try {
                const response = await getEngagedQuoteStatus(quoteData?.id);
                setEngagedQuoteStatusHistory(response);
            } catch (error) {
                console.log(error);
            } 
        }
        engagedQuoteFetchData();
    }, [quoteData, stateChanged]);

    useEffect(() => {
        if (engagedQuoteStatusHistory) {
            // set current step for progress bar
            if (engagedQuoteStatusHistory?.length > 0) {
                if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE) {
                    setCurrentStepSample(0);
                    setSampleProgressAlertMsg("Submit your request for samples.");
                } else if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_REQUESTED) {
                    setCurrentStepSample(1);
                    setSampleProgressAlertMsg("Sample request has been sent to the supplier.");
                } else if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT) {
                    setCurrentStepSample(2);
                    setSampleProgressAlertMsg("Samples are in transit. Please check the tracking information for the delivery status and press \"Confirm delivery\" when the samples arrive.");
                } else if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED) {
                    setCurrentStepSample(3);
                    setSampleProgressAlertMsg("Please provide your feedback to the supplier in the \"Questions & feedback\" section below for minor adjustment requests. When you are satisfied with the samples, press \"Proceed to next step\".");
                } else {
                    setCurrentStepSample(0); // invalid state
                    setSampleProgressAlertMsg("Invalid state.");
                }
            } else {
                setCurrentStepSample(0); // invalid state
                setSampleProgressAlertMsg("Invalid state.");
            }

            // set delivery address submitted
            /*if (engagedQuoteStatusHistory?.length > 0) {
                const lastIndex = engagedQuoteStatusHistory?.length - 1;
                setAddress1(engagedQuoteStatusHistory[lastIndex]?.values?.deliveryAddress?.address1);
                setAddress2(engagedQuoteStatusHistory[lastIndex]?.values?.deliveryAddress?.address2);
                setCity(engagedQuoteStatusHistory[lastIndex]?.values?.deliveryAddress?.city);
                setState(engagedQuoteStatusHistory[lastIndex]?.values?.deliveryAddress?.state);
                setZip(engagedQuoteStatusHistory[lastIndex]?.values?.deliveryAddress?.zip);
            }*/

            // set delivery tracking info
            if (engagedQuoteStatusHistory?.length > 0) {
                const sampleSent = engagedQuoteStatusHistory?.filter(h => h?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT);
                setTrackingNumber(sampleSent[0]?.values?.trackingNumber);
            }
        }
    }, [engagedQuoteStatusHistory]);

    const onDecoSampleDeliveredSubmit = async (e) => {
        e.preventDefault();
        if (deliveredBtnRef.current) {
            deliveredBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED, null)
                    .then(() => {
                        setMsg("Confirmed sample delivery successfully.");
                        setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                    });
            } catch {
                setErrorMsg("Confirming sample delivery failed, please try again.");
                setToastErrorShow(true);
            }

            deliveredBtnRef.current.removeAttribute("disabled");
        }
    }

    /*const onDecoSampleAdjustSubmit = async (e) => {
        e.preventDefault();
        if (requestAdjBtnRef.current) {
            requestAdjBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUST, null)
                    .then(() => {
                        setMsg("Sample adjustment request submitted successfully.");
                        setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                    });
            } catch {
                setErrorMsg("Submitting sample adjustment request failed, please try again.");
                setToastErrorShow(true);
            }

            requestAdjBtnRef.current.removeAttribute("disabled");
        }
    }*/

    const onDecoSampleCompleteSubmit = async (e) => {
        e.preventDefault();
        if (completeBtnRef.current) {
            completeBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_COMPLETE, null)
                    .then(() => {
                        setMsg("Completed pre-production sample step successfully.");
                        setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                        props.sendToParentStepCompleted();
                    });
            } catch {
                setErrorMsg("Completing pre-production sample step failed, please try again.");
                setToastErrorShow(true);
            }

            completeBtnRef.current.removeAttribute("disabled");
            setShowSampleCompletionModal(false);
        }
    }

    const handleAccidentalProceedNextStep = (e) => {
        setShowSampleCompletionModal(true);
    }

    const handleSampleCheckoutComplete = (e) => {
        //window.scrollTo(0, 0);
        setStateChanged(!stateChanged);
        setMsg("Sample requested successfully.");
        //setToastSuccessShow(true);
        setShowSampleRequestModal(false);
    }

    return (
        <Container fluid={true}>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h3>Pre-production sample cost</h3>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>            
                        <Row className='px-2 pb-2'>
                            <Row>
                                <div className={styles.accordContent}>
                                    <span>5 units of sample:</span>
                                    <span className={"ms-2"}>{quoteData?.decoSampleCost ? "$" + parseFloat(quoteData?.decoSampleCost).toFixed(2) : "N/A"}</span>
                                </div>
                                <div className={styles.cardListItemValueTC + " pt-3"}>
                                    Sample costs are waived if the final package production order is processed with this supplier. 
                                    <br/>
                                    If the final package production order is not processed with this supplier (i.e. different supplier is selected at a later stage), the sample cost will be invoiced to you.
                                </div>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br/>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h3>Sample tracker</h3>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='px-2 justify-content-md-center'>
                            <Col className='pb-3'>
                                <Alert className={styles.instructionBackgroundColor + ' my-2'}>
                                    <Row className='className="justify-content-md-center py-2'>
                                        <Col xs={2} lg={2} className='text-end'>
                                            <div className='mb-2'>
                                                <Image src={iconInstruction} />
                                            </div>
                                        </Col>
                                        <Col xs={10} lg={9}>
                                            <p style={{ fontWeight: 'bold'}}>What's next?</p>
                                            <p>{sampleProgressAlertMsg}</p>
                                            {/*<p>
                                                <span style={{ fontWeight: 'bold'}}>Step 1.</span> Submit your request. 
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 2.</span> Supplier receives your request.
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 3.</span> Supplier ships the request samples.
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 4.</span> Samples are delivered for your review.
                                            </p>*/}
                                            <p className='mb-0'>If you have any questions in the meantime, please leave a message in the "Questions & feedback".</p>
                                        </Col>
                                    </Row>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className='px-2 pb-3'>
                            <StepProgress>
                                <ProgressBarSample currentStep={currentStepSample} />
                            </StepProgress>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br/>            
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h3>Delivery tracking information</h3>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>            
                        <Row className='px-2 pb-2'>
                            <Row>
                                <div className={styles.accordContent}>
                                    <span>FedEx tracking number:</span>
                                    <span className={"ms-2"}>
                                        {trackingNumber ? (
                                            <a href={"https://www.fedex.com/wtrk/track/?trknbr=" + trackingNumber?.trim()}
                                                target='_blank'
                                                rel="noreferrer"
                                                style={{ textDecoration: "none"}}>
                                                <span>{trackingNumber}</span>
                                            </a>
                                        ) : (
                                            <span>N/A</span>
                                        )}   
                                    </span>
                                </div>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br/>
            <Card className='px-2'>
                <Card.Title className='px-2 pt-3 pb-2'>
                    <Image src={iconMessage} />
                    <span className='ps-3'>Questions & feedback</span>
                </Card.Title>
                <Card.Body className='pt-2'>
                    <Messages 
                        title={"Please submit any comments or questions."} 
                        buttonLabel={"Submit"}
                        quoteId={quoteData?.id} 
                        type={constants.TYPE_MSG_QUOTE_SAMPLE_DECO_FEEDBACK}
                        numberOfLines={3}
                        //disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED).length === 0 ? true : false} 
                        />
                </Card.Body>
            </Card>
            <br/>
            <Row className="px-2 py-2">
                {(errorMsg || msg) && (
                    <Row className='mx-1 pb-1'>
                        <Col md={{ span: 4, offset: 4 }}>
                            {errorMsg && (
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorMsg("")}
                                    dismissible
                                    className="mb-2 text-center">
                                    {errorMsg}
                                </Alert>
                            )}
                            {msg && (
                                <Alert
                                    variant="success" 
                                    onClose={() => setMsg("")} 
                                    dismissible 
                                    className="mb-2 text-center">
                                    {msg}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                )}
                <Col xs={5} md={4} xl={3} className='px-1'>
                    <div className="d-grid gap-2">
                        <Button onClick={() => setShowSampleRequestModal(true)}//type="submit"
                                className={styles.buttonOutline + " py-2"} size="sm" ref={requestBtnRef}
                                disabled={engagedQuoteStatusHistory?.length > 0 && engagedQuoteStatusHistory[0]?.status === constants?.STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE ? false : true}>
                            Request sample
                        </Button>
                        {/*{engagedQuoteStatusHistory?.length > 0 && engagedQuoteStatusHistory[0]?.status === constants?.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED && (
                        <>
                            <Button onClick={() => setShowSampleRequestModal(true)}//type="submit"
                                    variant="outline-primary" size="sm" ref={requestAdjBtnRef}
                                    disabled={engagedQuoteStatusHistory?.length > 0 ? true : false}>
                                Request adjustment
                            </Button>
                        </>
                        )}*/}
                    </div>  
                </Col>
                <Col xs={{ span: 5, offset: 2 }} md={{ span: 4, offset: 4 }} xl={{ span: 3, offset: 6 }} className='px-1'>
                    <div className="d-grid gap-2">
                        <Button className={styles.button + " py-2"} size="sm" ref={deliveredBtnRef}
                                onClick={onDecoSampleDeliveredSubmit}
                                disabled={engagedQuoteStatusHistory?.length > 0 && engagedQuoteStatusHistory[0]?.status === constants?.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT ? false : true}>
                            Confirm delivery
                        </Button>
                        <Button className={styles.button + " py-2"} size="sm" ref={completeBtnRef}
                                onClick={handleAccidentalProceedNextStep}
                                disabled={engagedQuoteStatusHistory?.length > 0 && engagedQuoteStatusHistory[0]?.status === constants?.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED ? false : true}>
                            Proceed to next step
                        </Button>
                    </div>
                </Col>
            </Row>
            <Modal show={showSampleRequestModal} 
                size="xl"
                fullscreen={false} 
                centered
                onHide={() => setShowSampleRequestModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Pre-production sample request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormSampleCheckout 
                        quoteId={quoteData?.id} 
                        sampleCost={quoteData?.decoSampleCost} 
                        type={"deco"} 
                        sendToParentSampleCheckoutComplete={handleSampleCheckoutComplete} />
                </Modal.Body>
            </Modal>
            <Modal show={showSampleCompletionModal} centered>
                <Modal.Header className='py-3'>
                    <Modal.Title>Satisfied with samples?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='py-2'>
                        <Image src={iconCheckGreen} width={50} />
                    </div>
                    <div className='py-3'>
                        All adjustments should be made within this step before you proceed. If you have additional change requests, please select "Cancel" to go back.  
                    </div>
                    <Row className='py-2 px-5'>
                        <Stack direction='vertical' gap={2}>
                            <Button variant="outline-secondary" className='py-2' size="sm" 
                                onClick={() => {
                                    setShowSampleCompletionModal(false);
                                }}>
                                Cancel
                            </Button>
                            <Button className={styles.button + " py-2"} size="sm" 
                                onClick={onDecoSampleCompleteSubmit}>
                                Proceed
                            </Button>
                        </Stack>
                    </Row>
                </Modal.Body>
            </Modal> 
        </Container>
    )
}