import styles from '../styles/styles.module.css';
import { Container, Row, Col, Stack, Breadcrumb } from 'react-bootstrap';
import { useLocation, Link } from "react-router-dom";
import QuoteCard from '../components/QuoteCard';
import * as constants from "../constants";

export const QuoteDetails = () => {
    const location = useLocation();
    const stateObj = JSON.parse(location?.state);
    const quoteData = stateObj?.data;

    return (
        <Container fluid={true} className='px-3 py-3'>
            {/*<Breadcrumb className="px-2 py-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: constants.PAGE_PATH_HOME}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: constants.PAGE_PATH_MY_QUOTES}}>My Quotes</Breadcrumb.Item>
                <Breadcrumb.Item active>Quote Details</Breadcrumb.Item>
            </Breadcrumb>*/}
            <Row className="px-2 pb-2">
                <Col>
                    <h4 className="mb-3">Quote Details</h4>
                </Col>
            </Row>
            <Row className='px-2'>
                <QuoteCard quoteData={quoteData} readOnly={true} />
            </Row>
            <Row className='px-2 py-3'>
                <Col>
                    <Stack direction="vertical" gap={3}>
                        <div className={styles.cardListItemLabel}>Terms and Conditions</div>
                        <div className={styles.cardListItemValueTC + " ms-auto"}>
                        All prices are preliminary until final artwork files and color targets are received, and samples are made. Final Prices will be confirmed upon pre-production sample approval.
                        Prices contained in this quotation are valid for 60 days from the quotation date. Package Maven recommends that all packs, components, materials and decorations are tested to ensure product compatibility, functionally and suitability of use. The conduct of these tests is the sole responsibility of the client. All modifications made at the client's request will be
                        charged in full. All mold and tooling costs will be charged in full in the event of order cancellation.
                        </div>
                    </Stack>
                </Col>
            </Row>
        </Container>
    )
}