import styles from '../styles/styles.module.css';
import { useState, useEffect, useRef } from "react";
//import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Alert, Button, Form, Accordion, Stack } from "react-bootstrap";
import { setEngagedQuoteStatus } from "../services/buyerServices";
import { auth } from "../firebase";
import { getUser } from "../services/userServices";
import * as constants from "../constants";

export const FormSampleSent = (props) => {
    const submitBtnRef = useRef();

    const [supplier, setSupplier] = useState();
    const [trackingNumber, setTrackingNumber] = useState("");

    // Alert 
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {    
        async function supplierFetchData() {
          try {
            const response = await getUser(auth?.currentUser?.email); 
            setSupplier(response);
          } catch (error) {
            console.log(error);
          } 
        }
        supplierFetchData();
    }, []);

    useEffect(() => {
        if (supplier) {
            // set buyer info
        }
    }, [supplier]);

    const onSampleRequestSubmit = async (e) => {
        e.preventDefault();
        if (submitBtnRef.current) {
            submitBtnRef.current.setAttribute("disabled", "disabled");

            const status = props?.type === "nonDeco" ? 
                constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT : // non deco sample 
                constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT; // pre production sample 

            try {
                setEngagedQuoteStatus(props?.quoteId, status, { trackingNumber: trackingNumber })
                    .then(() => {
                        setMsg("Tracking number recorded successfully.");
                        props?.sendToParentSampleSent();
                    });
            } catch {
                setErrorMsg("Recording tracking number failed, please try again.");
            }

            submitBtnRef.current.removeAttribute("disabled");
        }
    }

    return (
        <>
        <Container fluid>
            <Row>
                <Col>
                    <Form onSubmit={onSampleRequestSubmit}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Payment terms & conditions</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className='pb-2'>
                                        <p>
                                            <b>Delivery Arrangement and Sample Arrival:</b>
                                            <div className='px-3 py-2'>
                                                <li>The supplier must arrange the delivery of samples and provide the tracking number within 72 hours of receiving the sample request. The platform will provide a link for the buyer to track the delivery status and estimated arrival date.</li>
                                                <li>Samples must arrive within the date range specified in the delivery arrangement. Any changes to the committed delivery date must be communicated through the delivery website.</li>
                                            </div>
                                        </p>
                                        <p>
                                            <b>Confidentiality Agreement:</b>
                                            <div className='px-3 py-2'>
                                                <li>Both parties agree to maintain confidentiality of all proprietary information related to the sample, including design, specifications, and any other sensitive data. This confidentiality agreement remains in effect even after the conclusion of the project.</li>
                                            </div>
                                        </p>
                                        <p>
                                            <b>Payment Terms:</b>
                                            <div className='px-3 py-2'>
                                                <li>The final invoice will be issued upon the conclusion of the project.</li>
                                                <li>If the project ends without a purchase order, the sample cost will be invoiced at that time.</li>
                                                <li>If a purchase order is made, the sample cost will be waived.</li>
                                            </div>                                            
                                        </p>
                                        <p>
                                            <b>Questions or Concerns:</b>
                                            <div className='px-3 py-2'>
                                                <li>Please contact: <span style={{ fontWeight: 'bold'}}>hello@packagemaven.com</span></li>
                                            </div>                                            
                                        </p>
                                        <Row className="justify-content-md-center">
                                            <Col xs={8} lg={3} className='text-center'>
                                                <Form.Check
                                                    type={'checkbox'}
                                                    id={'ack-checkbox'}
                                                    label="Agree to terms & conditions"
                                                    feedback="You must agree before submitting."
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>                        
                        <br/>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Delivery tracking information</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col md={10}>
                                            <Form.Group className="mb-2" controlId="address1.control">
                                                <Form.Label className={styles.listItemLabel}>FedEx tracking number</Form.Label>
                                                <Form.Control type="text" value={trackingNumber} onChange={e => setTrackingNumber(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {errorMsg?.length > 0 && (
                            <div className='mt-3'>
                                <Alert
                                    variant="danger"
                                    onClose={() => setErrorMsg("")}
                                    dismissible>
                                    {errorMsg}
                                </Alert>
                            </div>
                        )}
                        {msg?.length > 0 && (
                            <div className='mt-3'>
                                <Alert
                                    variant="success" 
                                    onClose={() => setMsg("")} 
                                    dismissible>
                                    {msg}
                                </Alert>
                            </div>
                        )}
                        <Row className="mt-3 mb-2">
                            <div className='text-center'>
                                <Button type="submit" variant="outline-primary" size="sm" ref={submitBtnRef}>
                                    <span>Submit</span>
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
        </>
    )
}