import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
    Nav,
    Container,
    Row,
    Col,
    Card,
    Stack,
    Offcanvas,
    Button,
    Toast,
    ToastContainer,
    Spinner,
    Breadcrumb,
    Accordion,
    Alert
} from 'react-bootstrap'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import 'react-image-gallery/styles/css/image-gallery.css'
import moment from 'moment'
import { getUser } from '../services/userServices'
import { getSupplierQuotes } from '../services/supplierServices'
import { getFile } from '../services/fileStorageServices'
import * as constants from '../constants'
import { QuoteSubmitForm } from '../components/QuoteSubmitForm'
import { FileGallery } from '../components/FileGallery'
import { getProjectData } from '../services/buyerServices'

export const ProjectDetails = props => {
    const location = useLocation()
    const stateObj = JSON.parse(location?.state)
    const [projectData, setProjectData] = useState()
    const allowQuoteSubmit = Boolean(
        props?.allowQuoteSubmit
            ? props?.allowQuoteSubmit
            : stateObj?.allowQuoteSubmit
    )

    const [user, userLoading] = useAuthState(auth)
    const [userData, setUserData] = useState()

    const [buyer, setBuyer] = useState()

    const brand = projectData?.brand
    const status = projectData?.status
    const projectName = projectData?.name
    const initOrderQuantity = projectData?.initOrderQuantity
    const acceptLargerQuantityQuotes = projectData?.acceptLargerQuantityQuotes
    const deliveryDate = projectData?.deliveryDate
    const benchmarkLink = projectData?.benchmarkLink
    const targetUnitCost = projectData?.targetUnitCost
    const capacity = projectData?.capacity

    const formulationType = projectData?.formulation?.type
    const formulationViscosity = projectData?.formulation?.viscosity
    const canShareFormulation = projectData?.formulation?.canShare

    const baseType = projectData?.base?.type
    const baseMaterial = projectData?.base?.material
    const baseDeco = projectData?.base?.deco
    const baseFinish = projectData?.base?.finish

    const closureType = projectData?.closure?.type
    const closureMaterial = projectData?.closure?.material
    const closureDeco = projectData?.closure?.deco
    const closureFinish = projectData?.closure?.finish

    const applicatorType = projectData?.applicator?.type

    const isLabelNeeded = projectData?.label?.isNeeded
    const labelMaterial = projectData?.label?.material

    const hasPartnerFreightLogisticsCompany =
        projectData?.hasPartnerFreightLogisticsCompany
    const preferredIncoTerm = projectData?.preferredIncoTerm

    const decoDesignNeeds = projectData?.decoDesignNeeds
    const designFlexibility = projectData?.designFlexibility
    const receivingLocation = projectData?.receivingLocation
    const otherRequirements = projectData?.otherRequirements

    //const supplierAuditCertification = projectData?.supplierQualifications?.auditCertification;
    //const supplierSustainability = projectData?.supplierQualifications?.sustainability;
    //const supplierService = projectData?.supplierQualifications?.service;
    //const supplierCapacity = projectData?.supplierQualifications?.capacity;
    //const supplierInnovation = projectData?.supplierQualifications?.innovation;

    const fileRefData = projectData?.fileRefData
    const [designFiles, setDesignFiles] = useState([])
    const [designFilesLoading, setDesignFilesLoading] = useState(true)

    // Toast
    const [msg, setMsg] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    const [toastSuccessShow, setToastSuccessShow] = useState(false)
    const [toastErrorShow, setToastErrorShow] = useState(false)

    // offcanvas properties
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false)
    }
    const toggleShow = () => setShow(s => !s)

    const [prevSubmittedQuote, setPrevSubmittedQuote] = useState() // previsouly submitted quote if current user is a supplier
    const [quoteSubmitted, setQuoteSubmitted] = useState(false)
    const [quoteStateChanged, setQuoteStateChanged] = useState(false)

    useEffect(() => {
        async function userFetchData() {
            try {
                const response = await getUser(user?.email)
                setUserData(response)
            } catch (error) {
                console.log(error)
            }
        }
        userFetchData()
    }, [user])

    useEffect(() => {
        async function buyerFetchData() {
            try {
                const response = await getUser(projectData?.createdBy)
                setBuyer(response)
            } catch (error) {
                console.log(error)
            }
        }
        buyerFetchData()
    }, [projectData])

    useEffect(() => {
        async function designFilesFetchData() {
            try {
                if (fileRefData) {
                    var files = []
                    for (const data of fileRefData) {
                        const response = await getFile(data.blobName)
                        const fileExt = data?.blobName
                            ?.substring(data?.blobName?.lastIndexOf('.') + 1)
                            ?.toLowerCase()
                        const base64String = btoa(
                            new Uint8Array(response.data[0].data).reduce(
                                (data, byte) => data + String.fromCharCode(byte),
                                ''
                            )
                        )
                        const fileObj = {
                            base64String: base64String,
                            fileExt: fileExt
                        }
                        files.push(fileObj)
                    }
                    setDesignFiles(files)
                }
            } catch (error) {
                console.log(error)
            }
        }
        designFilesFetchData()
    }, [fileRefData])

    useEffect(() => {
        if (fileRefData && fileRefData.length > 0) {
            if (!designFiles || designFiles?.length === 0) {
                setDesignFilesLoading(true)
            } else {
                setDesignFilesLoading(false)
            }
        } else {
            // case where design files are not uploaded
            setDesignFilesLoading(false)
        }
    }, [designFiles, fileRefData])

    useEffect(() => {
        async function supplierQuoteFetchData() {
            try {
                const response = await getSupplierQuotes(
                    auth?.currentUser?.email,
                    projectData?.id
                )
                if (response && response?.length > 0) {
                    setPrevSubmittedQuote(response[0])
                    if (response[0]?.status !== constants.STATUS_QUOTE_DRAFT) {
                        setQuoteSubmitted(true)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (allowQuoteSubmit) {
            supplierQuoteFetchData()
        }
    }, [projectData, quoteStateChanged])

    useEffect(() => {
        async function fetchProject(projectId) {
            try {
                const response = await getProjectData(projectId)
                setProjectData(response)
            } catch (error) {
                console.log(error)
            }
        }

        if (props?.projectData) {
            setProjectData(props?.projectData)
        } else if (stateObj?.project && !projectData) {
            fetchProject(stateObj?.project.id)
        }
    }, [props?.projectData, stateObj?.project, projectData])

    const handleQuoteSubmittedFromChild = e => {
        setQuoteSubmitted(true)
        window.scrollTo(0, 0)
        setMsg('Quote submitted successfully.')
        setToastSuccessShow(true)
        handleClose()
    }

    const handleDraftQuoteSavedFromChild = e => {
        setQuoteStateChanged(!quoteStateChanged)
    }

    {
        /*function getFromPageLabel() {
            if (fromPage === constants.PAGE_PATH_HOME) {
                return "Home";
            } else if (fromPage === constants.PAGE_PATH_BROWSE_PROJECTS) {
                return "Browse Projects";
            } else if (fromPage === constants.PAGE_PATH_MY_PROJECTS) {
                return "My Projects";
            } else if (fromPage === constants.PAGE_PATH_MY_QUOTES) {
                return "My Quotes";
            } else if (fromPage === constants.PAGE_PATH_INIT_ASSESSMENT) {
                return "Initial Assessment";
            }
        }*/
    }

    return (
        <>
            <Container fluid={true}>
                <div
                    aria-live="polite"
                    aria-atomic="true"
                    className="position-relative"
                >
                    <ToastContainer
                        className="px-2"
                        position="top-end"
                        style={{ zIndex: 100 }}
                    >
                        {msg && (
                            <Toast
                                show={toastSuccessShow}
                                delay={3000}
                                autohide
                                bg="success"
                                onClose={() => {
                                    setToastSuccessShow(false)
                                    setMsg('')
                                }}
                            >
                                <Toast.Header>
                                    <strong className="me-auto">Package Maven</strong>
                                </Toast.Header>
                                <Toast.Body className="text-white">{msg}</Toast.Body>
                            </Toast>
                        )}
                        {errorMsg && (
                            <Toast
                                show={toastErrorShow}
                                delay={3000}
                                autohide
                                bg="danger"
                                onClose={() => {
                                    setToastErrorShow(false)
                                    setErrorMsg('')
                                }}
                            >
                                <Toast.Header>
                                    <strong className="me-auto">Package Maven</strong>
                                </Toast.Header>
                                <Toast.Body className="text-white">{errorMsg}</Toast.Body>
                            </Toast>
                        )}
                    </ToastContainer>
                </div>
                {/*<Breadcrumb className="px-2 py-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: constants.PAGE_PATH_HOME}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: fromPage }}>{getFromPageLabel()}</Breadcrumb.Item>
                <Breadcrumb.Item active>Project Details</Breadcrumb.Item>
            </Breadcrumb>*/}
                {allowQuoteSubmit && (
                    <Offcanvas
                        show={show}
                        placement="end"
                        onHide={handleClose}
                        scroll={true}
                        backdrop={false}
                        className="w-75"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Quote submit</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <QuoteSubmitForm
                                projectId={projectData?.id}
                                acceptLargerQuantityQuotes={acceptLargerQuantityQuotes}
                                initOrderQuantity={initOrderQuantity}
                                designFlexibility={designFlexibility}
                                prevSubmittedQuote={prevSubmittedQuote}
                                sendToParentQuoteSubmitted={handleQuoteSubmittedFromChild}
                                sendToParentDraftQuoteSaved={handleDraftQuoteSavedFromChild}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>
                )}
                {allowQuoteSubmit ? (
                    <Row className="px-3 pt-3 text-end">
                        <div>
                            <Button
                                className={styles.button + ' px-4 py-2'}
                                onClick={toggleShow}
                                disabled={quoteSubmitted ? true : false}
                            >
                                {quoteSubmitted ? 'Quote submitted' : 'Submit quote'}
                            </Button>
                        </div>
                    </Row>
                ) : (
                    /*
                      (status === constants.STATUS_PROJECT_DRAFT || status === constants.STATUS_PROJECT_INIT_ASSESSMENT) && (
                          <Button variant="primary">
                              <Nav.Link as={Link} to={constants.PAGE_PATH_PROJECT_EDIT} state={JSON.stringify({project: projectData})}>
                                  Edit
                              </Nav.Link>
                          </Button>                                        
                          )*/
                    <></>
                )}
                <Row className="px-3 py-3">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <Stack direction="horizontal" gap={5}>
                                    <h4>Project</h4>
                                </Stack>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row className="px-2">
                                    {user && userData?.role?.admin && (
                                        <Col xs={12}>
                                            <Alert variant="danger" className="mt-2 my-2">
                                                <Alert.Heading>Admin only</Alert.Heading>
                                                <Stack direction="vertical" gap={1}>
                                                    <div className={styles.detailItemLabel}>Author</div>
                                                    <div className={styles.detailItemValue}>
                                                        {buyer?.firstName +
                                                            ' ' +
                                                            buyer?.lastName +
                                                            ' (' +
                                                            buyer?.email +
                                                            ')'}
                                                    </div>
                                                </Stack>
                                            </Alert>
                                        </Col>
                                    )}
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Project name</div>
                                            <div className={styles.detailItemValue}>
                                                {projectName}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Brand</div>
                                            <div className={styles.detailItemValue}>{brand}</div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Initial order quantity
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {initOrderQuantity}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Capacity</div>
                                            <div className={styles.detailItemValue}>
                                                {capacity} ml
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Accepting quotes for larger order quantity
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {acceptLargerQuantityQuotes
                                                    ? acceptLargerQuantityQuotes
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Target unit cost
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {targetUnitCost
                                                    ? '$' + targetUnitCost
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Benchmark link
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {benchmarkLink ? (
                                                    <a
                                                        href={
                                                            benchmarkLink?.startsWith('https://')
                                                                ? benchmarkLink
                                                                : 'https://' + benchmarkLink
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <span>{benchmarkLink}</span>
                                                    </a>
                                                ) : (
                                                    <span>Not specified</span>
                                                )}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                <Row className="px-3 py-3">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <Stack direction="horizontal" gap={5}>
                                    <h4>Design</h4>
                                </Stack>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row className="px-2">
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Base type</div>
                                            <div className={styles.detailItemValue}>{baseType}</div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Base material
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {baseMaterial}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Base finish</div>
                                            <div className={styles.detailItemValue}>{baseFinish}</div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Base deco</div>
                                            <div className={styles.detailItemValue}>{baseDeco}</div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Closure type</div>
                                            <div className={styles.detailItemValue}>
                                                {closureType}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Closure material
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {closureMaterial}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Closure finish
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {closureFinish}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Closure deco</div>
                                            <div className={styles.detailItemValue}>
                                                {closureDeco}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Applicator type
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {applicatorType ? applicatorType : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>Label needed</div>
                                            <div className={styles.detailItemValue}>
                                                {isLabelNeeded ? isLabelNeeded : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Label material
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {labelMaterial ? labelMaterial : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Decoration design needs
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {decoDesignNeeds ? decoDesignNeeds : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Design flexibility
                                                <span style={{ fontStyle: 'italic' }}>
                                                    {' '}
                                                    - Open to similar design or new tooling
                                                </span>
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {designFlexibility
                                                    ? designFlexibility
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                {designFilesLoading ? (
                                    <Row className="px-2">
                                        <Col className="py-3">
                                            <div className={styles.detailItemLabel}>Design files</div>
                                            <Row className="mx-3 my-3">
                                                Loading ...
                                                <Spinner
                                                    animation="border"
                                                    variant="secondary"
                                                    role="status"
                                                    className="mx-3"
                                                >
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : (
                                    designFiles &&
                                    designFiles?.length > 0 && (
                                        <Row className="px-2 pb-3">
                                            <Col className="py-3">
                                                <div className={styles.detailItemLabel}>
                                                    Design files
                                                </div>
                                                <FileGallery files={designFiles} />
                                                {/*<ImageGallery 
                                            items={designFiles} 
                                            showThumbnails={true} 
                                            showNav={true}
                                            thumbnailPosition={'bottom'} 
                                            disableThumbnailScroll={true}
                                            showPlayButton={false} 
                                            showIndex={true} />*/}
                                            </Col>
                                        </Row>
                                    )
                                )}
                                <Row className="px-2">
                                    <Col className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Other requirements
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {otherRequirements
                                                    ? otherRequirements
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                <Row className="px-3 py-3">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <Stack direction="horizontal" gap={5}>
                                    <h4>Formulation</h4>
                                </Stack>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row className="px-2">
                                    <Col md={6} lg={3} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Formulation type
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {formulationType ? formulationType : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Formulation viscosity and pH level
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {formulationViscosity
                                                    ? formulationViscosity
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Formulation can be shared with suppliers
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {canShareFormulation
                                                    ? canShareFormulation
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                <Row className="px-3 py-3">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <Stack direction="horizontal" gap={5}>
                                    <h4>Delivery</h4>
                                </Stack>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row className="px-2">
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Required delivery date
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {moment
                                                    .unix(deliveryDate?.seconds)
                                                    .format('MM/DD/YYYY')}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Receiving location (City, State)
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {receivingLocation}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                                <Row className="px-2">
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Has parter freight company
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {hasPartnerFreightLogisticsCompany
                                                    ? hasPartnerFreightLogisticsCompany
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="py-3">
                                        <Stack direction="vertical" gap={1}>
                                            <div className={styles.detailItemLabel}>
                                                Preferred Inco Term
                                            </div>
                                            <div className={styles.detailItemValue}>
                                                {preferredIncoTerm
                                                    ? preferredIncoTerm
                                                    : 'Not specified'}
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
                {/*<Row className="px-3 pb-3">
                <Card>
                    <Card.Body>
                        <Card.Title>Supplier qualifications</Card.Title>
                        <Row>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Audit / Certification</div>
                                    <div>{supplierAuditCertification ? supplierAuditCertification : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Sustainability</div>
                                    <div>{supplierSustainability ? supplierSustainability : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Service</div>
                                    <div>{supplierService ? supplierService : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Capacity</div>
                                    <div>{supplierCapacity ? supplierCapacity : "Not specified"}</div>
                                </Stack>
                            </Col>
                            <Col xs={6} md={2} className="py-2">
                                <Stack direction="vertical" gap={1}>
                                    <div className={styles.detailItemLabel}>Innovation</div>
                                    <div>{supplierInnovation ? supplierInnovation : "Not specified"}</div>
                                </Stack>
                            </Col>
                        </Row>
                    </Card.Body>                            
                </Card>
            </Row>*/}
            </Container>
        </>
    )
}
