import styles from '../styles/styles.module.css';
import { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Stack, Button, Form, Spinner, Accordion, Image, Alert, Modal } from 'react-bootstrap';
import { setEngagedQuoteStatus, getEngagedQuoteStatus } from "../services/buyerServices";
import { getFile } from "../services/fileStorageServices";
import Messages from './Messages';
import * as constants from "../constants";
import iconMessage from '../images/icons/communication.png';
import iconCheckGreen from '../images/icons/checkGreen.png';
import iconInstruction from '../images/icons/instruction.png';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default function StepBuyerDesignReview(props) {
    //const projectId = stateObj?.projectId;
    const quoteData = props?.quoteData;

    const [stateChanged, setStateChanged] = useState(false);
    //const [projectData, setProjectData] = useState();
    const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState([]);

    const designFilesSubmitBtnRef = useRef();
    //const designFileRefData = projectData?.designFileRefData;
    const [filesRefData, setFilesRefData] = useState(null);
    const [designFiles, setDesignFiles] = useState([]);

    // Toast 
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    
    const [toastSuccessShow, setToastSuccessShow] = useState(false);
    const [toastErrorShow, setToastErrorShow] = useState(false);

    // Modal
    const [showCompletionModal, setShowCompletionModal] = useState(false);

    useEffect(() => {
        async function engagedQuoteFetchData() {
            try {
                setEngagedQuoteStatusHistory([]);
                const response = await getEngagedQuoteStatus(quoteData?.id);
                setEngagedQuoteStatusHistory(response);
                //console.log("Engaged Project", response);
            } catch (error) {
                console.log(error);
            } 
        }
        engagedQuoteFetchData();
    }, [quoteData, stateChanged]);

    useEffect(() => {
        if (engagedQuoteStatusHistory && engagedQuoteStatusHistory.length > 0) {
            if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW) {
                setFilesRefData(engagedQuoteStatusHistory[0]?.values?.designFileRefData);
            } 
        }
    }, [engagedQuoteStatusHistory]);

    useEffect(() => {    
        async function designFilesFetchData() {
          try {
            if (filesRefData) {
                var files = [];
                for (const data of filesRefData) {
                    const response = await getFile(data.blobName); 
                    var base64String = btoa(
                      new Uint8Array(response.data[0].data).reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    /*const fileObj = {
                        original: `data:image/png;base64,${base64String}`,
                        originalHeight: '500',
                        thumbnail: `data:image/png;base64,${base64String}`,
                        thumnailHeight: '50',
                    }
                    files.push(fileObj);*/
                    files.push(`data:image/png;base64,${base64String}`);
                };
                setDesignFiles(files);
            }
          } catch (error) {
            console.log(error);
          } 
        }
        designFilesFetchData();
    }, [filesRefData]);

    const onSubmitDesign = async (e) => {
        if (!designFiles || designFiles.length === 0) {
            setErrorMsg("Please attach design files to submit.")
        } else {
            setShowCompletionModal(true);
        }        
    }

    const onProceedSubmitDesign = async (e) => {
        e.preventDefault();
        if (designFilesSubmitBtnRef.current) {
            designFilesSubmitBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW, { designFiles: designFiles })
                    .then(() => {
                        setShowCompletionModal(false);
                        setMsg("Design files submitted successfully.");
                        //setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                    });
            } catch {
                setErrorMsg("Submitting design files failed, please try again.");
                //setToastErrorShow(true);
            }

            designFilesSubmitBtnRef.current.removeAttribute("disabled");
        }
    }

    return (
        <Container fluid className='px-2'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h4>Design review</h4>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='px-2 justify-content-md-center'>
                            <Col className='py-2 px-1'>
                                <Alert className={styles.instructionBackgroundColor + ' my-2'}>
                                    <Row className='className="justify-content-md-center py-2'>
                                        <Col xs={2} lg={2} className='text-end'>
                                            <div className='mb-2'>
                                                <Image src={iconInstruction} />
                                            </div>
                                        </Col>
                                        <Col xs={10} lg={9}>
                                            <p style={{ fontWeight: 'bold'}}>What's next?</p>
                                            <p>Let's finalize your packaging design before requesting decorated samples.</p>
                                            <p>
                                                <span style={{ fontWeight: 'bold'}}>Step 1.</span> Submit your designs. 
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 2.</span> Supplier reviews and provide feedback.
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 3.</span> Make design adjustments if needed.
                                            </p>
                                            <p className='mb-0'>If you have any questions in the meantime, please leave a message in the "Questions & feedback".</p>
                                        </Col>
                                    </Row>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className='px-2 py-3'>
                            <Card className='px-0'>
                                <Card.Title className='px-3 pt-3 pb-2'>
                                    <span>Submit design files</span>                                       
                                </Card.Title>
                                <Card.Body className='pt-2'>
                                  
                                    <Form.Label htmlFor="designFiles.control" className={styles.detailItemLabel}>Please upload 2D image file or PDF.</Form.Label>
                                    <FilePond
                                        files={designFiles}
                                        instantUpload={false}
                                        onupdatefiles={setDesignFiles}
                                        allowMultiple={true}
                                        maxFiles={20}
                                        dropOnPage={true}
                                        server={null}
                                        name="files"
                                        labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                                        imagePreviewHeight={150}
                                        id="designFiles.control"
                                        acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
                                        required
                                        disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE).length === 0 ? true : false}
                                    />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className='px-2 py-3'>
                            <Card className='px-0'>
                                <Card.Title className='px-3 pt-3 pb-2'>
                                    <Image src={iconMessage} />
                                    <span className='ps-3'>Questions & feedback</span>
                                </Card.Title>
                                <Card.Body className='pt-2'>
                                    <Messages 
                                        title={"Please submit any comments or questions."} 
                                        buttonLabel={"Submit"}
                                        quoteId={quoteData?.id} 
                                        type={constants.TYPE_MSG_QUOTE_DESIGN_REVIEW}
                                        numberOfLines={3}
                                        //disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW).length === 0 ? true : false} 
                                    />
                                </Card.Body>
                            </Card>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Row className='px-2 pb-3'>
                <Col className='px-1 pt-3'>                           
                    {errorMsg?.length > 0 && (
                        <div>
                            <Alert
                                variant="danger"
                                onClose={() => setErrorMsg("")}
                                dismissible>
                                {errorMsg}
                            </Alert>
                        </div>
                    )}
                    {msg?.length > 0 && (
                        <div>
                            <Alert
                                variant="success" 
                                onClose={() => setMsg("")} 
                                dismissible>
                                {msg}
                            </Alert>
                        </div>
                    )}     
                    <div className='text-end'>
                        <Button onClick={onSubmitDesign} ref={designFilesSubmitBtnRef} size='sm' className={styles.button + " py-2"}
                            disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE).length === 0 ? true : false}>
                            Submit design files
                        </Button>
                    </div>                                   
                </Col>
            </Row>            
            <Modal show={showCompletionModal} centered>
                <Modal.Header className='py-3'>
                    <Modal.Title>Did you attach all design files?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <div className='py-2'>
                        <Image src={iconCheckGreen} width={50} />
                    </div>
                    <div className='py-3'>
                        All design files should be attached before you proceed. If you have additional design files, please select "Cancel" and attach them.  
                    </div>
                    <Row className='py-2 px-5'>
                        <Stack direction='vertical' gap={2}>
                            <Button variant="outline-secondary" className='py-2' size="sm" 
                                onClick={() => {
                                    setShowCompletionModal(false);
                                }}>
                                Cancel
                            </Button>
                            <Button className={styles.button + " py-2"} size="sm" 
                                onClick={onProceedSubmitDesign}>
                                Proceed
                            </Button>
                        </Stack>
                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    )
}