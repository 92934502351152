import styles from '../styles/styles.module.css';
import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Type } from 'react-bootstrap-table2-editor';

export const EditableTable = (props) => {

    const columns = [
    {
        dataField: 'id',
        text: "Id"
    },
    {
        dataField: 'category',
        text: 'Category',
        editor: {
            type: Type.SELECT,
            options: [
                {
                    value: "Base",
                    label: "Base"
                },
                {
                    value: "Closure",
                    label: "Closure"
                },
                {
                    value: "Applicator",
                    label: "Applicator"
                },
                {
                    value: "Finish",
                    label: "Finish"
                },
                {
                    value: "Decoration",
                    label: "Decoration"
                },
                {
                    value: "Additional",
                    label: "Additional"
                }
            ]
        }
      }, {
        dataField: 'description',
        text: 'Description'
      }, {
        dataField: 'price',
        type: 'number',
        text: 'Unit price ($)'
      }];

    const DefaultData = [
        {
            id : 1,
            category: "Base",
            description: "",
            price: ""
        },
        {
            id: 2,
            category: "Closure",
            description: "",
            price: ""
        },
        {
            id: 3,
            category: "Applicator",
            description: "",
            price: ""
        },
        {
            id: 4,
            category: "Finish",
            description: "",
            price: ""
        },
        {
            id: 5,
            category: "Decoration",
            description: "",
            price: ""
        },
        {
            id: 6,
            category: "Additional",
            description: "",
            price: ""
        },
    ]

    const [tableData, setTableData] = useState((props.data && props.data.length > 0) ? props.data : DefaultData);
    const [rowId, setRowId] = useState(7);
    const [selectedRows, setSelectedRows] = useState([]);

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectColumn: false,
        hideSelectAll: true,
        selected: selectedRows,
        clickToEdit: true,
        //bgColor: '#c8e6c9',
        onSelect: (row, isSelect, rowIndex, e) => {
            let tempSelectedRows = selectedRows;

            if (isSelect) {
                tempSelectedRows?.push(row.id);
            } else {
                const index = tempSelectedRows?.indexOf(row.id);
                if (index !== -1) {
                    tempSelectedRows?.splice(index, 1);
                }
            }

            setSelectedRows(tempSelectedRows);
        }
    }

    const handleAddRow = (e) => {
        let tempTableData = tableData;
        tempTableData.push({ id: rowId, category: "", description: "", price: ""});
        setTableData(tempTableData);
        setRowId(rowId + 1);
    }

    const handleDeleteRow = (e) => {
        if (selectedRows?.length > 0) {
            let tempTableData = tableData;
            for (const rowId of selectedRows) {
                if (rowId > 0) {
                    tempTableData?.splice(rowId-1, 1);
                }                
            }

            // reset tableData ids
            for (let i=0; i<tempTableData?.length; i++) {
                let updatedRowData = { ...tempTableData[i], id: i+1 }
                tempTableData[i] = updatedRowData;
            }

            setTableData([...tempTableData]);
            setSelectedRows([]);
            setRowId(tempTableData?.length + 1);
        }
    }

    function afterSaveCell(oldValue, newValue) {
        /*console.log('--after save cell--');
        console.log('oldValue', oldValue);
        console.log('newValue', newValue);
        console.log(`and the type is ${typeof newValue}`);
        console.log('tableData', tableData);*/
        console.log("cell value entered");
        props.sendToParentTableData(tableData);
    }

    return (
        <>
            <Row>
                <Col><span className={styles.detailItemLabel}>Double click table cell to edit</span></Col>
                <Col className='text-end'>
                    <Button variant='outline-secondary' size='sm' onClick={handleDeleteRow}>Delete row</Button>
                    <Button variant='outline-secondary' className='ms-2' size='sm' onClick={handleAddRow}>Add row</Button>
                </Col>
            </Row>
            <Row className='pt-2'>
                <BootstrapTable 
                    keyField='id'
                    data={ tableData }
                    columns={ columns }
                    selected={ selectedRows }
                    selectRow={ selectRow }
                    cellEdit={ cellEditFactory({ 
                        mode: 'dbclick', 
                        blurToSave: true,
                        afterSaveCell 
                    }) }
                />
            </Row>
        </>
    )
}