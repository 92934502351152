import './styles/App.css';
import { Routes, Route } from 'react-router-dom';
import { PageLayout } from "./components/PageLayout";
import { Home } from './pages/Home';
import { Projects } from './pages/Projects';
import { MyQuotes } from './pages/MyQuotes';
import { ProjectProgressBuyer } from './pages/ProjectProgressBuyer';
import { ProjectProgressSupplier } from './pages/ProjectProgressSupplier';
import { QuoteDetails } from './pages/QuoteDetails';
import { ProjectDetails } from './pages/ProjectDetails';
import { MyProjects } from './pages/MyProjects';
import { RegisterProfile } from './pages/RegisterProfile';
import { InitialAssessment } from './pages/InitialAssessment';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

import AuthRoute from "./components/AuthRoute";
import Register from "./pages/Register";
import Login from "./pages/Login";
import * as constants from "./constants";

//import PasswordReset from './pages/PasswordReset';

const Pages = () => {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path={constants.PAGE_PATH_HOME} element={<Home />} />
        <Route path={constants.PAGE_PATH_BROWSE_PROJECTS} element={<Projects />} />
        <Route path={constants.PAGE_PATH_MY_QUOTES} element={<MyQuotes />} />
        <Route path={constants.PAGE_PATH_PROJECT_PROGRESS_BUYER} element={<ProjectProgressBuyer />} />
        <Route path={constants.PAGE_PATH_PROJECT_PROGRESS_SUPPLIER} element={<ProjectProgressSupplier />} />
        <Route path={constants.PAGE_PATH_PROJECT_DETAILS} element={<ProjectDetails />} />
        <Route path={constants.PAGE_PATH_QUOTE_DETAILS} element={<QuoteDetails />} />
        <Route path={constants.PAGE_PATH_MY_PROJECTS} element={<MyProjects />} />
        <Route path={constants.PAGE_PATH_INIT_ASSESSMENT} element={<InitialAssessment />} />
        <Route path={constants.PAGE_PATH_REGISTER_PROFILE} element={<RegisterProfile />} />
      </Route>
      <Route path="/" element={<Home />} />
      <Route path={constants.PAGE_PATH_REGISTER} element={<Register />} />
      <Route path={constants.PAGE_PATH_LOGIN} element={<Login />} />
      <Route path={constants.PAGE_PATH_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      {/*<Route path="/passwordreset" element={<PasswordReset />} />*/}
    </Routes>
  );
};

function App() {
  return (
    <PageLayout>
      <Pages />
    </PageLayout>
  )
}

export default App;
