import styles from '../styles/styles.module.css';
import { useState, useEffect, useRef } from 'react';
import { auth } from "../firebase";
import { Row, Col, Stack, Button, InputGroup, Form, Alert } from 'react-bootstrap';
import { submitQuote, createQuote } from "../services/supplierServices";
import { getFile } from "../services/fileStorageServices";
import * as constants from "../constants";
import { EditableTable } from './EditableTable';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const QuoteSubmitForm = (props) => {
    const projectId = props?.projectId;
    const acceptLargerQuantityQuotes = props?.acceptLargerQuantityQuotes;
    const initOrderQuantity = parseFloat(props?.initOrderQuantity);
    const designFlexibility = props?.designFlexibility;
    const prevQuote = props?.prevSubmittedQuote;
    const iteration = props?.iteration;

    const saveDraftBtnRef = useRef();
    const submitQuoteBtnRef = useRef();
    
    const [msg, setMsg] = useState([]);
    const [errorMsg, setErrorMsg] = useState([]);

    const [isExactDesign, setIsExactDesign] = useState(prevQuote?.isExactDesign ? prevQuote?.isExactDesign : "");
    const [productionTimeline, setProductionTimeline] = useState(prevQuote?.productionTimeline ? prevQuote?.productionTimeline : "");
    const [shippingTimeline, setShippingTimeline] = useState(prevQuote?.shippingTimeline ? prevQuote?.shippingTimeline : "");
    const [moq, setMoq] = useState(prevQuote?.moq ? prevQuote?.moq : "");

    const designTechDrawingFileRefData = prevQuote?.designTechDrawingFileRefData;
    const componentPictureFileRefData = prevQuote?.componentPictureFileRefData;
    const [designTechDrawingFiles, setDesignTechDrawingFiles] = useState([]);
    const [componentPictureFiles, setComponentPictureFiles] = useState([]);
    const [unitCost, setUnitCost] = useState(prevQuote?.unitCost ? prevQuote?.unitCost : []);

    const [shippingLocation, setShippingLocation] = useState(prevQuote?.shippingLocation ? prevQuote?.shippingLocation : "");
    const [shippingCost, setShippingCost] = useState(prevQuote?.shippingCost ? prevQuote?.shippingCost : "");
    const [nonDecoSampleCost, setNonDecoSampleCost] = useState(prevQuote?.nonDecoSampleCost ? prevQuote?.nonDecoSampleCost : "");
    const [decoSampleCost, setDecoSampleCost] = useState(prevQuote?.decoSampleCost ? prevQuote?.decoSampleCost : "");

    const [estQuoteTotal, setEstQuoteTotal] = useState(getQuoteTotal());
    //const [estQuoteStateChanged, setEstQuoteStateChanged] = useState(false);

    const [expiresIn, setExpiresIn] = useState(prevQuote?.expiresIn ? prevQuote?.expiresIn : "60");
    const [comments, setComments] = useState(prevQuote?.comments ? prevQuote?.comments : "");

    useEffect(() => {    
        async function filesFetchData() {
          try {
            if (designTechDrawingFileRefData) {
                var files = [];
                for (const data of designTechDrawingFileRefData) {
                    const response = await getFile(data.blobName); 
                    var base64String = btoa(
                      new Uint8Array(response.data[0].data).reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    files.push(`data:image/png;base64,${base64String}`);
                };
                setDesignTechDrawingFiles(files);
            }
          } catch (error) {
            console.log(error);
          } 
        }
        filesFetchData();
    }, []);

    useEffect(() => {    
        async function filesFetchData() {
          try {
            if (componentPictureFileRefData) {
                var files = [];
                for (const data of componentPictureFileRefData) {
                    const response = await getFile(data.blobName); 
                    var base64String = btoa(
                      new Uint8Array(response.data[0].data).reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    files.push(`data:image/png;base64,${base64String}`);
                };
                setComponentPictureFiles(files);
            }
          } catch (error) {
            console.log(error);
          } 
        }
        filesFetchData();
    }, []);


    const onSubmitQuote = async (e) => {
        e.preventDefault();
        if (submitQuoteBtnRef.current) {
            submitQuoteBtnRef.current.setAttribute("disabled", "disabled");

            if (isQuoteEligible()) {
                try {
                    if (iteration) { // creates new quote record for adjustment iteration steps
                        createQuote(projectId, auth?.currentUser?.email, isExactDesign, productionTimeline, shippingTimeline, shippingLocation, 
                            designTechDrawingFiles, componentPictureFiles, 
                            moq, unitCost, shippingCost, nonDecoSampleCost, decoSampleCost, getQuoteTotal(), expiresIn, comments,
                            constants.STATUS_QUOTE_ADJUSTED)
                            .then(() => {
                                props.sendToParentAdjustedQuoteSubmitted();
                                setMsg(["Adjusted quote submission successful."]);
                            });
                    } else { // updates existing quote while the quote is in init assessment
                        submitQuote(projectId, auth?.currentUser?.email, isExactDesign, productionTimeline, shippingTimeline, shippingLocation, 
                            designTechDrawingFiles, componentPictureFiles, 
                            moq, unitCost, shippingCost, nonDecoSampleCost, decoSampleCost, getQuoteTotal(), expiresIn, comments,
                            constants.STATUS_QUOTE_SUBMITTED)//STATUS_QUOTE_INIT_ASSESSMENT)
                            .then(() => {
                                props.sendToParentQuoteSubmitted();
                                setMsg(["Quote submission successful."]);
                            });
                    }
                } catch {
                    setErrorMsg(["Quote submission failed, please try again."]);
                    submitQuoteBtnRef.current.removeAttribute("disabled");
                } finally {
                    // button remain disabled
                }
            } else {
                submitQuoteBtnRef.current.removeAttribute("disabled");
            }
        }
    }

    const handleSaveQuoteAsDraft = (e) => {
        e.preventDefault();
        if (saveDraftBtnRef.current) {
            saveDraftBtnRef.current.setAttribute("disabled", "disabled");

            try {
                submitQuote(projectId, auth?.currentUser?.email, isExactDesign, productionTimeline, shippingTimeline, shippingLocation, 
                    designTechDrawingFiles, componentPictureFiles, 
                    moq, unitCost, shippingCost, nonDecoSampleCost, decoSampleCost, getQuoteTotal(), expiresIn, comments,
                    constants.STATUS_QUOTE_DRAFT)
                    .then(() => {
                        props.sendToParentDraftQuoteSaved();
                        setMsg(["Saving draft quote successful."]);
                    });
            } catch {
                setErrorMsg(["Saving quote draft failed, please try again."]);
            } finally {
                saveDraftBtnRef.current.removeAttribute("disabled");
            }
        }
    }

    function isQuoteEligible() {
        let tempErrorMsg = ["Please correct the following:"];
        let result = true;
        if ((acceptLargerQuantityQuotes === "No") && (moq > initOrderQuantity)) {
            result = false;
            tempErrorMsg.push("- This project does not accept quotes with larger MOQ than the initial order qantity.");
        }
        if ((designFlexibility === "No") && (isExactDesign === "No")) {
            result = false;
            tempErrorMsg.push("\n- This project only accepts exact design.");
        }
        if (unitCost.filter(uc => uc?.category === "Base").length < 1) {
            result = false;
            tempErrorMsg.push("\n- Please enter Base component in the unit cost section.");
        }
        if (unitCost.filter(uc => uc?.category === "Base" && (uc?.description?.trim() === "" || uc?.price === 0)).length > 0) {
            result = false;
            tempErrorMsg.push("\n- Please enter Base component unit cost details: description and price.");
        }
        if (unitCost.filter(uc => uc?.category === "Closure").length < 1) {
            result = false;
            tempErrorMsg.push("\n- Please enter Closure component in the unit cost section.");
        }
        if (unitCost.filter(uc => uc?.category === "Closure" && (uc?.description?.trim() === "" || uc?.price === 0)).length > 0) {
            result = false;
            tempErrorMsg.push("\n- Please enter Closure component unit cost details: description and price.");
        }
        if (!result) {
            setErrorMsg(tempErrorMsg);
        }
        return result;
    }

    function getQuoteTotal() {
        let unitCostSum = 0;
        for (const obj of unitCost) {
            if (obj?.price !== "") {
                unitCostSum = unitCostSum + parseFloat(obj?.price);
            }
        }

        return (
            unitCostSum
            * 
            (moq ? parseInt(moq) : 0) 
            + 
            (shippingCost ? parseFloat(shippingCost) : 0) 
            + 
            (nonDecoSampleCost ? parseFloat(nonDecoSampleCost) : 0)
            +
            (decoSampleCost ? parseFloat(decoSampleCost) : 0)
            )
            .toFixed(2);
    }

    useEffect(() => {    
        setEstQuoteTotal(getQuoteTotal());
    }, [moq, unitCost, shippingCost, nonDecoSampleCost, decoSampleCost]);

    const handleEditableTableDataUpdate = (editableTableData) => {
        setUnitCost([...editableTableData]);
    }

    return (
        <Form onSubmit={onSubmitQuote}>
            <Row className='pb-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Requirements related</h6></Row>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="exactDesignControl" className={styles.detailItemLabel}>Exact design</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Select onChange={e => setIsExactDesign(e.target.value)} value={isExactDesign} id="exactDesignControl" required>
                            <option value={""}>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Select>
                    </InputGroup>
                </Col>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="moqControl" className={styles.detailItemLabel}>Min order quantity</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="number" id="moqControl" value={moq} 
                            onChange={e => setMoq(e.target.value)}
                            required />
                    </InputGroup>
                </Col>
            </Row>
            <Row className='py-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Timeline</h6></Row>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="productionTimelineControl" className={styles.detailItemLabel}>Production timeline</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" id="productionTimelineControl" value={productionTimeline} onChange={e => setProductionTimeline(e.target.value)} required />
                        <InputGroup.Text className={styles.detailItemLabel}>Weeks</InputGroup.Text>
                    </InputGroup>
                </Col>   
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="shippingTimelineControl" className={styles.detailItemLabel}>Shipping timeline</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" id="shippingTimelineControl" value={shippingTimeline} onChange={e => setShippingTimeline(e.target.value)} required />
                        <InputGroup.Text className={styles.detailItemLabel}>Weeks</InputGroup.Text>
                    </InputGroup>
                </Col>   
            </Row>
            <Row className='py-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Logistics</h6></Row>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="shippingLocationControl" className={styles.detailItemLabel}>Shipping location</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" id="shippingLocationControl" value={shippingLocation} placeholder='City, Country' onChange={e => setShippingLocation(e.target.value)} required />
                    </InputGroup>
                </Col>
            </Row>
            <Row className="py-2">
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Design / Technical drawings</h6></Row>
                <Col>
                    <Form.Label htmlFor="designTechDrawingFiles.control" className={styles.detailItemLabel}>Please upload 2D image file or PDF.</Form.Label>
                    <FilePond
                        files={designTechDrawingFiles}
                        instantUpload={false}
                        onupdatefiles={setDesignTechDrawingFiles}
                        allowMultiple={true}
                        maxFiles={20}
                        dropOnPage={true}
                        server={null}
                        name="files"
                        labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                        imagePreviewHeight={150}
                        id="designTechDrawingFiles.control"
                        acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
                        required
                    />
                </Col>
            </Row>
            <Row className="py-2">
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Component pictures</h6></Row>
                <Col>
                    <Form.Label htmlFor="componentPictureFiles.control" className={styles.detailItemLabel}>Please upload 2D image file or PDF.</Form.Label>
                    <FilePond
                        files={componentPictureFiles}
                        instantUpload={false}
                        onupdatefiles={setComponentPictureFiles}
                        allowMultiple={true}
                        maxFiles={20}
                        dropOnPage={true}
                        server={null}
                        name="files"
                        labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                        imagePreviewHeight={150}
                        id="componentPictureFiles.control"
                        acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
                        required
                    />
                </Col>
            </Row>
            <Row className='py-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Unit cost</h6></Row>
                <Col xs={12}>                    
                    <EditableTable data={unitCost} sendToParentTableData={handleEditableTableDataUpdate} />
                </Col>
            </Row>
            <Row className='py-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Other cost</h6></Row>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="shippingCostControl" className={styles.detailItemLabel}>Est. final delivery / shipping</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text" id="shippingCostControl" value={shippingCost} onChange={e => setShippingCost(e.target.value)} required />
                    </InputGroup>
                </Col>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="nonDecoSampleCostControl" className={styles.detailItemLabel}>Non-deco sample: 5 units (incl. shipping)</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text" id="nonDecoSampleCostControl" value={nonDecoSampleCost} onChange={e => setNonDecoSampleCost(e.target.value)} required />
                    </InputGroup>
                </Col>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="decoSampleCostControl" className={styles.detailItemLabel}>Deco sample: 5 units (incl. shipping)</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text" id="decoSampleCostControl" value={decoSampleCost} onChange={e => setDecoSampleCost(e.target.value)} required />
                    </InputGroup>
                </Col>
            </Row>
            <Row className='py-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Calculated</h6></Row>
                <Col xxl={8}>
                    <Form.Label htmlFor="quoteControl" className={styles.detailItemLabel}>Estimated quote total
                        <span style={{ fontStyle: "italic"}}> = Moq * Unit Cost + Shipping + Non-deco samples + Pre-production samples</span></Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control type="text"
                            id="quoteControl"
                            value={estQuoteTotal}
                            disabled={true}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row className='py-2'>
                <Row className='pb-1'><h6 style={{fontWeight: "bold"}}>Expiration</h6></Row>
                <Col lg={6} xxl={4}>
                    <Form.Label htmlFor="quoteExpiryControl" className={styles.detailItemLabel}>Quote expires in</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Select onChange={e => setExpiresIn(e.target.value)} defaultValue={expiresIn} id="quoteExpiryControl" required>
                            <option>60</option>
                            <option>90</option>
                        </Form.Select>
                        <InputGroup.Text>Days</InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
            <Row className='py-2'>
                <Col>
                    <Form.Label htmlFor="commentsControl" className={styles.detailItemLabel}>Other information (optional)</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control as="textarea" aria-label="With textarea" rows={5} id="commentsControl" onChange={e => setComments(e.target.value)} />
                    </InputGroup>
                </Col>
            </Row>       
            {errorMsg?.length > 0 && (
                <div>
                    <Alert
                        variant="danger"
                        onClose={() => setErrorMsg([])}
                        dismissible>
                        {errorMsg?.map(line => (
                            <span key={"errMsg" + errorMsg?.indexOf(line)}>{line}<br/></span>
                        ))}
                    </Alert>
                </div>
            )}
            {msg?.length > 0 && (
                <div>
                    <Alert
                        variant="success" 
                        onClose={() => setMsg([])} 
                        dismissible>
                        {msg?.map(line => (
                            <span key={"msg" + msg?.indexOf(line)}>{line}<br/></span>
                        ))}
                    </Alert>
                </div>
            )}
            <Row className='py-2'>
                <Stack direction='horizontal' gap={2} className="mx-auto">
                    {!iteration && (<Button ref={saveDraftBtnRef} onClick={handleSaveQuoteAsDraft} className={styles.buttonOutline + " py-2"}>Save draft</Button>)}
                    <Button ref={submitQuoteBtnRef} type="submit" className={styles.button + " py-2"}>Submit</Button>
                </Stack>
            </Row>
        </Form>
    )
}