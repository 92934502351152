import styles from '../styles/styles.module.css';
import { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Alert, Button, Form, InputGroup, Accordion, Stack } from "react-bootstrap";
import { auth } from "../firebase";
import { addProjectData, updateProjectData } from "../services/buyerServices";
import { getUser } from "../services/userServices";
import { useNavigate } from "react-router-dom";
import * as constants from "../constants";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import Date Picker
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

export const ProjectUpload = (props) => {
    const navigate = useNavigate();

    const saveDraftBtnRef = useRef();
    const submitBtnRef = useRef();
    const [buyerUser, setBuyerUser] = useState();

    const [projectId, setProjectId] = useState("");
    const [projectName, setProjectName] = useState("");
    const [initOrderQuantity, setInitOrderQuantity] = useState(null);
    const [acceptLargerQuantityQuotes, setAcceptLargerQuantityQuotes] = useState("");
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [benchmarkLink, setBenchmarkLink] = useState("");
    const [targetUnitCost, setTargetUnitCost] = useState("");
    const [capacity, setCapacity] = useState("");

    const [formulationType, setFormulationType] = useState("");
    const [formulationViscosity, setFormulationViscosity] = useState("");
    const [canShareFormulation, setCanShareFormulation] = useState("");

    const [baseType, setBaseType] = useState("");
    const [baseMaterial, setBaseMaterial] = useState("");
    const [baseDeco, setBaseDeco] = useState("");
    const [baseFinish, setBaseFinish] = useState("");

    const [closureType, setClosureType] = useState("");
    const [closureMaterial, setClosureMaterial] = useState("");
    const [closureDeco, setClosureDeco] = useState("");
    const [closureFinish, setClosureFinish] = useState("");

    const [applicatorType, setApplicatorType] = useState("");

    const [isLabelNeeded, setIsLabelNeeded] = useState("");
    const [labelMaterial, setLabelMaterial] = useState("");

    const [hasPartnerFreightLogisticsCompany, setHasPartnerFreightLogisticsCompany] = useState("");
    const [preferredIncoTerm, setPreferredIncoTerm] = useState("");

    const [decoDesignNeeds, setDecoDesignNeeds] = useState("");
    const [designFlexibility, setDesignFlexibility] = useState("");
    const [receivingLocation, setReceivingLocation] = useState("");

    const [otherRequirements, setOtherRequirements] = useState("");

    const [supplierAuditCertification, setSupplierAuditCertification] = useState("");
    const [supplierSustainability, setSupplierSustainability] = useState("");
    const [supplierService, setSupplierService] = useState("");
    const [supplierCapacity, setSupplierCapacity] = useState("");
    const [supplierInnovation, setSupplierInnovation] = useState("");

    const [designFiles, setDesignFiles] = useState([]);

    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        async function buyerUserFetchData() {
            try {
                const response = await getUser(auth?.currentUser?.email);
                setBuyerUser(response);
            } catch (error) {
                console.log(error);
            }
        }
        buyerUserFetchData();
    }, []);

    function buildProjectObject() {
        return {
            brand: buyerUser?.company,
            name: projectName,
            initOrderQuantity: initOrderQuantity,
            acceptLargerQuantityQuotes: acceptLargerQuantityQuotes,
            deliveryDate: deliveryDate,
            benchmarkLink: benchmarkLink,
            targetUnitCost: targetUnitCost,
            capacity: capacity,
            formulation: {
                type: formulationType,
                viscosity: formulationViscosity,
                canShare: canShareFormulation,
            },
            base: {
                type: baseType,
                material: baseMaterial,
                deco: baseDeco,
                finish: baseFinish,
            },
            closure: {
                type: closureType,
                material: closureMaterial,
                deco: closureDeco,
                finish: closureFinish,
            },
            applicator: {
                type: applicatorType,
            },
            label: {
                isNeeded: isLabelNeeded,
                material: labelMaterial,
            },
            hasPartnerFreightLogisticsCompany: hasPartnerFreightLogisticsCompany,
            preferredIncoTerm: preferredIncoTerm,
            decoDesignNeeds: decoDesignNeeds,
            designFlexibility: designFlexibility,
            receivingLocation: receivingLocation,
            otherRequirements: otherRequirements,
            supplierQualifications: {
                auditCertification: supplierAuditCertification,
                sustainability: supplierSustainability,
                service: supplierService,
                capacity: supplierCapacity,
                innovation: supplierInnovation,
            }
        }
    }
    const onSaveDraft = async (e) => {
        e.preventDefault();
        if (saveDraftBtnRef.current) {
            saveDraftBtnRef.current.setAttribute("disabled", "disabled");

            if (!projectId || projectId.length === 0) { // project has not been saved as draft
                try {
                    addProjectData(buildProjectObject(), designFiles, constants.STATUS_PROJECT_DRAFT)
                        .then(result => {
                            setMsg("Creating project draft successful.");
                            setProjectId(result?.id);
                        });
                } catch {
                    setErrorMsg("Creating project draft failed, please try again.");
                } finally {
                    saveDraftBtnRef.current.removeAttribute("disabled");
                }
            } else { // project has been saved as draft
                try {
                    updateProjectData(projectId, buildProjectObject(), designFiles, constants.STATUS_PROJECT_DRAFT)
                        .then(() => {
                            setMsg("Updating project draft successful.");
                            //setProjectId(result?.id);
                        });
                } catch {
                    setErrorMsg("Updating project draft failed, please try again.");
                } finally {
                    saveDraftBtnRef.current.removeAttribute("disabled");
                }
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (submitBtnRef.current) {
            submitBtnRef.current.setAttribute("disabled", "disabled");

            if (!projectId || projectId.length === 0) { // project has not been saved as draft
                try {
                    addProjectData(buildProjectObject(), designFiles, constants.STATUS_PROJECT_INIT_ASSESSMENT)
                        .then(result => {
                            setMsg("Project created successfully. Package Maven expert will run the initial assessment, and provide feedback.");
                            props.sendToParentProjectCreated();
                            setProjectId(result);
                            navigate(constants.PAGE_PATH_MY_PROJECTS);
                        });
                } catch {
                    setErrorMsg("Creating project failed, please try again.");
                    submitBtnRef.current.removeAttribute("disabled");
                } finally {
                    // button remain disabled
                }
            } else { // project has been saved as draft
                try {
                    updateProjectData(projectId, buildProjectObject(), designFiles, constants.STATUS_PROJECT_INIT_ASSESSMENT)
                        .then(() => {
                            setMsg("Project updated successfully. Package Maven expert will run the initial assessment, and provide feedback.");
                            props.sendToParentProjectCreated();
                            navigate(constants.PAGE_PATH_MY_PROJECTS);
                        });
                } catch {
                    setErrorMsg("Creating project failed, please try again.");
                    submitBtnRef.current.removeAttribute("disabled");
                } finally {
                    // button remain disabled
                }
            }
        }
    }

    return (
        <>
            <Container fluid={true}>
                {/*<Breadcrumb className="pb-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: constants.PAGE_PATH_HOME}}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: constants.PAGE_PATH_MY_PROJECTS}}>My Projects</Breadcrumb.Item>
                <Breadcrumb.Item active>Create New Project</Breadcrumb.Item>
            </Breadcrumb>*/}
                <Form onSubmit={onSubmit}>
                    <Row className="px-3 py-3">
                        <Accordion defaultActiveKey="0" className='px-0'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Project</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className='px-2'>
                                        <Col xs={12} className="py-3">
                                            <Form.Group controlId="projectName.control">
                                                <Form.Label className={styles.detailItemLabel}>Project name<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="text" onChange={e => setProjectName(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col xs={12} md={6} className="py-3">
                                            <Form.Group controlId="initialOrderQuantity.control">
                                                <Form.Label className={styles.detailItemLabel}>Initial order quantity<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="number" onChange={e => setInitOrderQuantity(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} className="py-3">
                                            <Form.Group controlId="capacity.control">
                                                <Form.Label className={styles.detailItemLabel}>Capacity<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <InputGroup>
                                                    <Form.Control type="number" onChange={e => setCapacity(e.target.value)} required />
                                                    <InputGroup.Text>ml</InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Stack direction='horizontal'>
                                            <span className={styles.detailItemLabel}>(Optional)</span>
                                            <div className='ps-2' style={{ width: '100%' }}><hr /></div>
                                        </Stack>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col xs={12} md={6} className="py-3">
                                            <Form.Group controlId="intQuotesForHigherQuantity.control">
                                                <Form.Label className={styles.detailItemLabel}>Accepting larger order quotes</Form.Label>
                                                <Form.Select onChange={e => setAcceptLargerQuantityQuotes(e.target.value)} value={acceptLargerQuantityQuotes} >
                                                    <option value={""}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} className="py-3">
                                            <Form.Group controlId="targetUnitCost.control">
                                                <Form.Label className={styles.detailItemLabel}>Target unit cost</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>$</InputGroup.Text>
                                                    <Form.Control type="text" onChange={e => setTargetUnitCost(e.target.value)} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col xs={12} className="py-3">
                                            <Form.Group controlId="benchmarkLink.control">
                                                <Form.Label className={styles.detailItemLabel}>Benchmark link</Form.Label>
                                                <Form.Control type="text" onChange={e => setBenchmarkLink(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row className="px-3 py-3">
                        <Accordion defaultActiveKey="0" className='px-0'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Design</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className='px-2'>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="baseType.control">
                                                <Form.Label className={styles.detailItemLabel}>Base type<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setBaseType(e.target.value)} value={baseType} required >
                                                    <option value={""}>Select</option>
                                                    <option>Bottle</option>
                                                    <option>Jar</option>
                                                    <option>Tube</option>
                                                    <option>Pouch</option>
                                                    <option>Stick</option>
                                                    <option>Tottle</option>
                                                    <option>Compact</option>
                                                    <option>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="baseMaterial.control">
                                                <Form.Label className={styles.detailItemLabel}>Base material<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setBaseMaterial(e.target.value)} value={baseMaterial} required >
                                                    <option value={""}>Select</option>
                                                    <option>Metal - AL</option>
                                                    <option>Metal - STEEL</option>
                                                    <option>Metal - ZINC ALLOY</option>
                                                    <option>Metal - TIN</option>
                                                    <option>Plastic - ABS</option>
                                                    <option>Plastic - HDPE</option>
                                                    <option>Plastic - LDPE</option>
                                                    <option>Plastic - MDPE</option>
                                                    <option>Plastic - PET</option>
                                                    <option>Plastic - PETG</option>
                                                    <option>Plastic - PETE</option>
                                                    <option>Plastic - PP</option>
                                                    <option>Plastic - PCR PP</option>
                                                    <option>Plastic - PCR PE</option>
                                                    <option>Plastic - PCR PET</option>
                                                    <option>Plastic - SAN</option>
                                                    <option>Plastic - PMMA</option>
                                                    <option>Silicone</option>
                                                    <option>Glass</option>
                                                    <option>Paper</option>
                                                    <option>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="baseFinish.control">
                                                <Form.Label className={styles.detailItemLabel}>Base finish<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setBaseFinish(e.target.value)} value={baseFinish} required >
                                                    <option value={""}>Select</option>
                                                    <option>Matte Spray Coating</option>
                                                    <option>In-mold Matte</option>
                                                    <option>Glossy Spary Coating</option>
                                                    <option>Metalization</option>
                                                    <option>Vacuum Metalization</option>
                                                    <option>Frosting</option>
                                                    <option>Acid Etching</option>
                                                    <option>Other</option>
                                                    <option>None</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="baseDeco.control">
                                                <Form.Label className={styles.detailItemLabel}>Base decoration<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setBaseDeco(e.target.value)} value={baseDeco} required >
                                                    <option value={""}>Select</option>
                                                    <option>Hot Stamping</option>
                                                    <option>Pad Printing</option>
                                                    <option>Digital Printing</option>
                                                    <option>Silk Screen</option>
                                                    <option>Offset Printing</option>
                                                    <option>Masking</option>
                                                    <option>Decaling</option>
                                                    <option>Clear Coating</option>
                                                    <option>Other</option>
                                                    <option>None</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="closureType.control">
                                                <Form.Label className={styles.detailItemLabel}>Closure type<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setClosureType(e.target.value)} value={closureType} required >
                                                    <option value={""}>Select</option>
                                                    <option>Sprayer</option>
                                                    <option>Dropper</option>
                                                    <option>Flip Top Cap</option>
                                                    <option>Push Pull Cap</option>
                                                    <option>Disc Top Cap</option>
                                                    <option>Twist Open Cap</option>
                                                    <option>Nail Polish Cap</option>
                                                    <option>Threaded Cap</option>
                                                    <option>Plug</option>
                                                    <option>Tube Cap</option>
                                                    <option>Pump</option>
                                                    <option>Overcap</option>
                                                    <option>Sealing Disc</option>
                                                    <option>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="closureMaterial.control">
                                                <Form.Label className={styles.detailItemLabel}>Closure material<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setClosureMaterial(e.target.value)} value={closureMaterial} required >
                                                    <option value={""}>Select</option>
                                                    <option>Metal - AL</option>
                                                    <option>Metal - STEEL</option>
                                                    <option>Metal - ZINC ALLOY</option>
                                                    <option>Metal - TIN</option>
                                                    <option>Plastic - ABS</option>
                                                    <option>Plastic - HDPE</option>
                                                    <option>Plastic - LDPE</option>
                                                    <option>Plastic - MDPE</option>
                                                    <option>Plastic - PET</option>
                                                    <option>Plastic - PETG</option>
                                                    <option>Plastic - PETE</option>
                                                    <option>Plastic - PP</option>
                                                    <option>Plastic - PCR PP</option>
                                                    <option>Plastic - PCR PE</option>
                                                    <option>Plastic - PCR PET</option>
                                                    <option>Plastic - SAN</option>
                                                    <option>Plastic - PMMA</option>
                                                    <option>Silicone</option>
                                                    <option>Glass</option>
                                                    <option>Paper</option>
                                                    <option>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="closureFinish.control">
                                                <Form.Label className={styles.detailItemLabel}>Closure finish<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setClosureFinish(e.target.value)} value={closureFinish} required >
                                                    <option value={""}>Select</option>
                                                    <option>Matte Spray Coating</option>
                                                    <option>In-mold Matte</option>
                                                    <option>Glossy Spary Coating</option>
                                                    <option>Metalization</option>
                                                    <option>Vacuum Metalization</option>
                                                    <option>Frosting</option>
                                                    <option>Acid Etching</option>
                                                    <option>Other</option>
                                                    <option>None</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} className="py-3">
                                            <Form.Group controlId="closureDeco.control">
                                                <Form.Label className={styles.detailItemLabel}>Closure decoration<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setClosureDeco(e.target.value)} value={closureDeco} required >
                                                    <option value={""}>Select</option>
                                                    <option>Hot Stamping</option>
                                                    <option>Pad Printing</option>
                                                    <option>Digital Printing</option>
                                                    <option>Silk Screen</option>
                                                    <option>Offset Printing</option>
                                                    <option>Masking</option>
                                                    <option>Decaling</option>
                                                    <option>Clear Coating</option>
                                                    <option>Other</option>
                                                    <option>None</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Stack direction='horizontal'>
                                            <span className={styles.detailItemLabel}>(Optional)</span>
                                            <div className='ps-2' style={{ width: '100%' }}><hr /></div>
                                        </Stack>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col className='pt-3 pb-2'>
                                            <div className={styles.detailItemLabel}>Design files</div>
                                            <Form.Label htmlFor="designFiles.control" className={styles.detailItemLabel}>Please upload 2D image files and PDF only.</Form.Label>
                                            <FilePond
                                                files={designFiles}
                                                instantUpload={false}
                                                onupdatefiles={setDesignFiles}
                                                allowMultiple={true}
                                                maxFiles={20}
                                                dropOnPage={true}
                                                server={null}
                                                name="files"
                                                labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                                                imagePreviewHeight={150}
                                                id="designFiles.control"
                                                acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
                                            //required
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col lg={4} className="py-3">
                                            <Form.Group controlId="applicatorType.control">
                                                <Form.Label className={styles.detailItemLabel}>Applicator type</Form.Label>
                                                <Form.Select onChange={e => setApplicatorType(e.target.value)} value={applicatorType} >
                                                    <option value={""}>Select</option>
                                                    <option>Flock Tip</option>
                                                    <option>Doe Foot Tip</option>
                                                    <option>Needle Nozzle</option>
                                                    <option>Orifice Reducer</option>
                                                    <option>Zamach Tip</option>
                                                    <option>Ceramic Tip</option>
                                                    <option>Roller Ball</option>
                                                    <option>Soft Tip</option>
                                                    <option>Brush Tip</option>
                                                    <option>Sponge</option>
                                                    <option>Other</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col lg={4} className="py-3">
                                            <Form.Group controlId="label.control">
                                                <Form.Label className={styles.detailItemLabel}>Label</Form.Label>
                                                <Form.Select onChange={e => setIsLabelNeeded(e.target.value)} value={isLabelNeeded} >
                                                    <option value={""}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} className="py-3">
                                            <Form.Group controlId="applicatorMaterial.control">
                                                <Form.Label className={styles.detailItemLabel}>Label material</Form.Label>
                                                <Form.Select onChange={e => setLabelMaterial(e.target.value)} value={labelMaterial} >
                                                    <option value={""}>Select</option>
                                                    <option>PE</option>
                                                    <option>BoPP</option>
                                                    <option>LDPE</option>
                                                    <option>Paper</option>
                                                    <option>Mylar</option>
                                                    <option>Multi-layer</option>
                                                    <option>Other</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col className="py-3">
                                            <Form.Group controlId="decorationDesignNeeds.control">
                                                <Form.Label className={styles.detailItemLabel}>Decoration design needs</Form.Label>
                                                <Form.Control as="textarea" onChange={e => setDecoDesignNeeds(e.target.value)} rows={4} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col lg={6} className="py-3">
                                            <Form.Group controlId="designFlexibility.control">
                                                <Form.Label className={styles.detailItemLabel}>Design flexibility
                                                    <br />
                                                    <span style={{ fontStyle: "italic" }}>- Open to similar design or new tooling</span>
                                                </Form.Label>
                                                <Form.Select onChange={e => setDesignFlexibility(e.target.value)} value={designFlexibility} >
                                                    <option value={""}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col className="py-3">
                                            <Form.Group controlId="otherReq.control">
                                                <Form.Label className={styles.detailItemLabel}>Other requirements</Form.Label>
                                                <Form.Control as="textarea" rows={3} onChange={e => setOtherRequirements(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row className="px-3 py-3">
                        <Accordion defaultActiveKey="0" className='px-0'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Formulation</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className='px-2'>
                                        <Col lg={4} className='py-3'>
                                            <Form.Group controlId="formulationType.control">
                                                <Form.Label className={styles.detailItemLabel}>Formulation type<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Select onChange={e => setFormulationType(e.target.value)} value={formulationType} required>
                                                    <option value={""}>Select</option>
                                                    <option>Gel</option>
                                                    <option>Cream</option>
                                                    <option>Balm</option>
                                                    <option>Emulsion</option>
                                                    <option>SPF (Sun care)</option>
                                                    <option>Oil</option>
                                                    <option>Loose powder</option>
                                                    <option>Pressed powder</option>
                                                    <option>Other (Chemical, hair care, etc.)</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Stack direction='horizontal'>
                                            <span className={styles.detailItemLabel}>(Optional)</span>
                                            <div className='ps-2' style={{ width: '100%' }}><hr /></div>
                                        </Stack>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col lg={8} className='py-3'>
                                            <Form.Group controlId="formulationViscosity.control">
                                                <Form.Label className={styles.detailItemLabel}>What are the viscosity and pH level of the formulation?</Form.Label>
                                                <Form.Control as="textarea" rows={1} onChange={e => setFormulationViscosity(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} className='py-3'>
                                            <Form.Group controlId="canShareFormulation.control">
                                                <Form.Label className={styles.detailItemLabel}>Can you share the formulation with suppliers?</Form.Label>
                                                <Form.Select onChange={e => setCanShareFormulation(e.target.value)} value={canShareFormulation} >
                                                    <option value={""}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row className='px-3 py-3'>
                        <Accordion defaultActiveKey="0" className='px-0'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Stack direction="horizontal" gap={5}>
                                        <h4>Delivery</h4>
                                    </Stack>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className='px-2'>
                                        <Col lg={6} className='py-3'>
                                            <Form.Group controlId="deliveryDate.control">
                                                <Form.Label className={styles.detailItemLabel}>Required delivery date<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Row>
                                                    <DatePicker className={"py-1"} onChange={setDeliveryDate} value={deliveryDate} minDate={new Date()} required />
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className='py-3'>
                                            <Form.Group controlId="receivingLocation.control">
                                                <Form.Label className={styles.detailItemLabel}>Receiving location (City, State)<span className='ms-1' style={{ color: 'red' }}>*</span></Form.Label>
                                                <Form.Control type="text" onChange={e => setReceivingLocation(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='px-2'>
                                        <Stack direction='horizontal'>
                                            <span className={styles.detailItemLabel}>(Optional)</span>
                                            <div className='ps-2' style={{ width: '100%' }}><hr /></div>
                                        </Stack>
                                    </Row>
                                    <Row className='px-2'>
                                        <Col lg={6} className='py-3'>
                                            <Form.Group controlId="partnerLogisticsCompany.control">
                                                <Form.Label className={styles.detailItemLabel}>Do you have your own freight company that you partner with?</Form.Label>
                                                <Form.Select onChange={e => setHasPartnerFreightLogisticsCompany(e.target.value)} value={hasPartnerFreightLogisticsCompany} >
                                                    <option value={""}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} className='py-3'>
                                            <Form.Group controlId="incoTerm.control">
                                                <Form.Label className={styles.detailItemLabel}>Do you have preferred INCO TERM for the freight?</Form.Label>
                                                <Form.Select onChange={e => setPreferredIncoTerm(e.target.value)} value={preferredIncoTerm} >
                                                    <option value={""}>Select</option>
                                                    <option>EXW - Ex Works</option>
                                                    <option>FCA - Free Carrier</option>
                                                    <option>FAS - Free Alongside Ship</option>
                                                    <option>FOB - Free On Board</option>
                                                    <option>CFR - Cost and Frieght</option>
                                                    <option>CIF - Cost, Insurance and Freight</option>
                                                    <option>CPT - Carriage Paid To</option>
                                                    <option>CIP - Carriage and Insurance Paid to</option>
                                                    <option>DAT - Delivered At Terminal</option>
                                                    <option>DPU - Delivered At Place</option>
                                                    <option>DDP - Delivered Duty Paid</option>
                                                    <option>Not sure</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                    <Row className="px-3 py-3">
                        {(errorMsg || msg) && (
                            <Row className='mx-1 pb-1'>
                                <Col md={{ span: 4, offset: 4 }}>
                                    {errorMsg && (
                                        <Alert
                                            variant="danger"
                                            onClose={() => setErrorMsg("")}
                                            dismissible
                                            className="mb-2 text-center">
                                            {errorMsg}
                                        </Alert>
                                    )}
                                    {msg && (
                                        <Alert
                                            variant="success"
                                            onClose={() => setMsg("")}
                                            dismissible
                                            className="mb-2 text-center">
                                            {msg}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Col xs={5} md={4} xl={3} className='px-0'>
                            <div className="d-grid gap-2">
                                <Button variant='outline-secondary' className='py-2' size="sm" onClick={() => navigate(-1)}>Cancel</Button>
                            </div>
                        </Col>
                        <Col xs={{ span: 5, offset: 2 }} md={{ span: 4, offset: 4 }} xl={{ span: 3, offset: 6 }} className='px-0'>
                            <div className="d-grid gap-2">
                                <Button ref={saveDraftBtnRef} onClick={onSaveDraft} className={styles.buttonOutline + " py-2"} size="sm">Save draft</Button>
                                <Button ref={submitBtnRef} type="submit" className={styles.button + " py-2"} size="sm">Submit</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}