import styles from '../styles/styles.module.css'
import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Stack,
  Alert,
  Row,
  Col,
  Spinner,
  Form,
  Card,
  ListGroup,
  Carousel
} from 'react-bootstrap'
import moment from 'moment'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getUser } from '../services/userServices'
import {
  updateQuoteStatus,
  updateProjectStatus
} from '../services/buyerServices'
import { getFile, getFileExtension } from '../services/fileStorageServices'
//import ImageGallery from "react-image-gallery";
import Messages from './Messages'
import * as constants from '../constants'
import { FileGallery } from './FileGallery'

export default function QuoteCard(props) {
  const readOnly = props?.readOnly
  const projectId = props?.projectId
  const data = props?.quoteData

  const [user, userLoading] = useAuthState(auth)
  const [userData, setUserData] = useState()

  const [supplier, setSupplier] = useState()

  const designTechDrawingFileRefData = data?.designTechDrawingFileRefData
  const [designTechDrawingFiles, setDesignTechDrawingFiles] = useState([])
  const [designTechDrawingFilesLoading, setDesignTechDrawingFilesLoading] =
    useState(true)

  const componentPictureFileRefData = data?.componentPictureFileRefData
  const [componentPictureFiles, setComponentPictureFiles] = useState([])
  const [componentPictureFilesLoading, setComponentPictureFilesLoading] =
    useState(true)

  const [finishOns, setFinishOns] = useState([])
  const [decoCounters, setDecoCounters] = useState([])

  const [selected, setSelected] = useState(
    data?.status === constants.STATUS_QUOTE_SUBMITTED ||
      data?.status === constants.STATUS_QUOTE_DRAFT
      ? false
      : true
  ) // || data?.status === constants.STATUS_QUOTE_INIT_ASSESSMENT) ? false : true);
  const [msg, setMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    async function userFetchData() {
      try {
        const response = await getUser(user?.email)
        setUserData(response)
      } catch (error) {
        console.log(error)
      }
    }
    userFetchData()
  }, [user])

  useEffect(() => {
    async function filesFetchData() {
      try {
        if (designTechDrawingFileRefData) {
          let dtdFiles = []
          for (const data of designTechDrawingFileRefData) {
            const response = await getFile(data?.blobName)
            const file = response.data[0].data
            const buffer = new Uint8Array(file)
            const base64String = btoa(
              buffer.reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )

            const fileExt = await getFileExtension(buffer)
            const fileObj = {
              //original: `data:image/png;base64,${base64String}`,
              //originalHeight: '450',
              //thumbnail: `data:image/png;base64,${base64String}`,
              //thumnailHeight: '50',
              //fullscreen: `data:image/png;base64,${base64String}`,
              base64String: base64String,
              pdfFile: `data:application/pdf;base64,${base64String}`,
              imgFile: `data:image/png;base64,${base64String}`,
              fileExt
            }
            dtdFiles.push(fileObj)
          }
          setDesignTechDrawingFiles(dtdFiles)
        }
      } catch (error) {
        console.log(error)
      }
    }
    filesFetchData()
  }, [designTechDrawingFileRefData])

  useEffect(() => {
    if (!designTechDrawingFiles || designTechDrawingFiles?.length === 0) {
      setDesignTechDrawingFilesLoading(true)
    } else {
      setDesignTechDrawingFilesLoading(false)
    }
  }, [designTechDrawingFiles])

  useEffect(() => {
    async function filesFetchData() {
      try {
        if (componentPictureFileRefData) {
          let cpFiles = []
          for (const data of componentPictureFileRefData) {
            const response = await getFile(data.blobName)
            const file = response.data[0].data
            const buffer = new Uint8Array(file)

            const fileExt = await getFileExtension(buffer)

            const base64String = btoa(
              buffer.reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            const fileObj = {
              //original: `data:image/png;base64,${base64String}`,
              //originalHeight: '450',
              //thumbnail: `data:image/png;base64,${base64String}`,
              //thumnailHeight: '50',
              //fullscreen: `data:image/png;base64,${base64String}`,
              base64String: base64String,
              fileExt
            }
            cpFiles.push(fileObj)
          }
          setComponentPictureFiles(cpFiles)
        }
      } catch (error) {
        console.log(error)
      }
    }
    filesFetchData()
  }, [componentPictureFileRefData])

  useEffect(() => {
    if (!componentPictureFiles || componentPictureFiles?.length === 0) {
      setComponentPictureFilesLoading(true)
    } else {
      setComponentPictureFilesLoading(false)
    }
  }, [componentPictureFiles])

  useEffect(() => {
    setFinishOns(getInitialFinishOnObject(data))
    setDecoCounters(getInitialDecorationCounterObject(data))
  }, [data])

  function getInitialFinishOnObject(data) {
    let results = []
    const finishObjects = data?.unitCost?.filter(
      uc => uc?.category === 'Finish'
    )

    for (let i = 0; i < finishObjects?.length; i++) {
      results.push({ ...finishObjects[i], onOffOption: true })
    }
    return results
  }

  function updateFinishOnArray(id, option) {
    let tempData = finishOns
    for (let obj of tempData) {
      if (obj?.id === id) {
        let newObj = { ...obj, onOffOption: option }
        //console.log("newObj", newObj);
        tempData[tempData.indexOf(obj)] = newObj
      }
    }
    setFinishOns([...tempData])
  }

  function getInitialDecorationCounterObject(data) {
    let results = []
    const decoObjects = data?.unitCost?.filter(
      uc => uc?.category === 'Decoration'
    )

    for (let i = 0; i < decoObjects?.length; i++) {
      results.push({ ...decoObjects[i], counter: 1 })
    }
    return results
  }

  function updateDecorationCounterArray(id, option) {
    let tempData = decoCounters
    for (let obj of tempData) {
      if (obj?.id === id) {
        let newObj = { ...obj, counter: obj?.counter + option }
        //console.log("newObj", newObj);
        tempData[tempData.indexOf(obj)] = newObj
      }
    }
    setDecoCounters([...tempData])
  }

  // this function returns the number of days remaining before expiration
  function getRemainingDays(expiresInValue, submittedOn) {
    var results
    results = Math.floor(
      expiresInValue -
        moment
          .duration(moment().diff(moment.unix(submittedOn?.seconds)))
          .asDays()
    )
    return results
  }

  useEffect(() => {
    async function supplierFetchData() {
      try {
        const response = await getUser(data?.createdBy)
        setSupplier(response)
      } catch (error) {
        console.log(error)
      }
    }
    supplierFetchData()
  }, [data])

  const handleSelectQuote = async e => {
    e.preventDefault()
    try {
      updateProjectStatus(
        projectId,
        constants.STATUS_PROJECT_SUPPLIER_SELECTED
      ).then(() => {
        //setMsg("Selecting quote successful.");
        try {
          updateQuoteStatus(data?.id, constants.STATUS_QUOTE_SELECTED).then(
            () => {
              setSelected(true)
              setMsg('Selecting quote successful.')
              props.sendToParentQuoteSelected()
            }
          )
        } catch {
          setErrorMsg('Selecting quote failed, please try again.')
        }
      })
    } catch {
      setErrorMsg('Updating project status failed, please try again.')
    }
  }

  function getUnitCostTotal() {
    let unitCostSum = 0
    if (data?.unitCost) {
      for (const obj of data?.unitCost) {
        if (obj?.price !== '') {
          if (obj?.category === 'Finish') {
            if (
              finishOns?.filter(fo => fo?.id === obj?.id)?.at(0)?.onOffOption
            ) {
              unitCostSum = unitCostSum + parseFloat(obj?.price)
            }
          } else if (obj?.category === 'Decoration') {
            unitCostSum =
              unitCostSum +
              decoCounters?.filter(dc => dc?.id === obj?.id)?.at(0)?.counter *
                parseFloat(obj?.price)
          } else {
            unitCostSum = unitCostSum + parseFloat(obj?.price)
          }
        }
      }
    }
    return unitCostSum
  }

  function getQuoteTotalEstimate() {
    const results =
      getUnitCostTotal() * parseFloat(data?.moq) +
      (data?.shippingCost ? parseFloat(data?.shippingCost) : 0) +
      (data?.nonDecoSampleCost ? parseFloat(data?.nonDecoSampleCost) : 0) +
      (data?.decoSampleCost ? parseFloat(data?.decoSampleCost) : 0)

    return results.toFixed(2)
  }

  return (
    <Card>
      {/*<Card.ImgOverlay className="position-relative">
        <Stack direction="horizontal" gap={3}>
          <div>
              <Badge pill bg="secondary">Quotes {data.quotes.length}</Badge>
          </div>
          <div className="ms-auto">
            {data?.quotes?.findIndex(quote => quote?.createdBy === auth?.currentUser?.email) !== -1 ? <Badge pill bg="danger">Quoted</Badge> : <></>}
          </div>
        </Stack>
      </Card.ImgOverlay>*/}
      {/*<Card.Img variant="top" src={`data:image/png;base64,${previewImage}`} height={200} />*/}
      <ListGroup className="list-group-flush">
        {user && userData?.role?.admin && (
          <ListGroup.Item>
            <Alert variant="danger" className="mt-2 my-2">
              <Alert.Heading>Admin only</Alert.Heading>
              <Stack direction="vertical" gap={1}>
                <div className={styles.detailItemLabel}>Author</div>
                <div className={styles.detailItemValue}>
                  {supplier?.firstName +
                    ' ' +
                    supplier?.lastName +
                    ' (' +
                    supplier?.email +
                    ')'}
                </div>
              </Stack>
            </Alert>
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Supplier</div>
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {supplier?.company}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Shipping location</div>
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {data?.shippingLocation ? data?.shippingLocation : 'N/A'}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Timeline</div>
            <div className="ms-auto">
              <Stack direction="horizontal" gap={5}>
                <Stack direction="vertical" className="text-center">
                  <div className={styles.cardListItemLabel}>Production</div>
                  <div className={styles.cardListItemValue}>
                    {data?.productionTimeline
                      ? data?.productionTimeline + ' Weeks'
                      : 'N/A'}
                  </div>
                </Stack>
                <Stack direction="vertical" className="text-center">
                  <div className={styles.cardListItemLabel}>Shipping</div>
                  <div className={styles.cardListItemValue}>
                    {data?.shippingTimeline
                      ? data?.shippingTimeline + ' Weeks'
                      : 'N/A'}
                  </div>
                </Stack>
              </Stack>
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col className="pb-3">
              <Stack direction="vertical" gap={3}>
                <div className={styles.cardListItemLabel}>
                  Design / Technical drawings
                </div>
                <div className="ms-2">
                  <Row className="pt-2 pb-2">
                    {designTechDrawingFilesLoading ? (
                      <Row className="mx-3 my-3">
                        Loading ...
                        <Spinner
                          animation="border"
                          variant="secondary"
                          role="status"
                          className="mx-3"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </Row>
                    ) : (
                      <FileGallery files={designTechDrawingFiles} />
                    )}
                  </Row>
                </div>
              </Stack>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col className="pb-3">
              <Stack direction="vertical" gap={3}>
                <div className={styles.cardListItemLabel}>
                  Component pictures
                </div>
                <div className="ms-2">
                  <Row className="pt-2 pb-2">
                    {componentPictureFilesLoading ? (
                      <Row className="mx-3 my-3">
                        Loading ...
                        <Spinner
                          animation="border"
                          variant="secondary"
                          role="status"
                          className="mx-3"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </Row>
                    ) : (
                      <FileGallery files={componentPictureFiles} />
                    )}
                  </Row>
                </div>
              </Stack>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>MOQ</div>
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {data?.moq ? data?.moq : 'N/A'}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col>
              <div className={styles.cardListItemLabel}>Unit cost</div>
            </Col>
          </Row>
          <Row>
            {data?.unitCost &&
              data?.unitCost.length > 0 &&
              data?.unitCost?.map(
                uc =>
                  uc?.name !== '' &&
                  (uc?.category === 'Finish' ? (
                    <Col className="pb-2 px-1" key={uc?.id}>
                      <Stack direction="vertical" className="text-center">
                        <div className="align-self-center">
                          <Stack direction="horizontal" gap={1}>
                            <Button
                              size="sm"
                              variant={
                                finishOns
                                  ?.filter(fo => fo?.id === uc?.id)
                                  ?.at(0)?.onOffOption
                                  ? 'outline-secondary'
                                  : 'secondary'
                              }
                              className="py-0 px-0"
                              style={{ maxHeight: '25px', width: '30px' }}
                              onClick={() => updateFinishOnArray(uc?.id, false)}
                            >
                              Off
                            </Button>
                            <Button
                              size="sm"
                              variant={
                                finishOns
                                  ?.filter(fo => fo?.id === uc?.id)
                                  ?.at(0)?.onOffOption
                                  ? 'secondary'
                                  : 'outline-secondary'
                              }
                              className="py-0 px-0"
                              style={{ maxHeight: '25px', width: '30px' }}
                              onClick={() => updateFinishOnArray(uc?.id, true)}
                            >
                              On
                            </Button>
                          </Stack>
                        </div>
                        <div className={styles.cardListItemLabel + ' pt-1'}>
                          {uc?.category}
                        </div>
                        <div className={styles.cardListItemValue + ' py-1'}>
                          {uc?.name}
                        </div>
                        <div className={styles.cardListItemValue}>
                          {finishOns?.filter(fo => fo?.id === uc?.id)?.at(0)
                            ?.onOffOption
                            ? uc?.price
                              ? '$' + parseFloat(uc?.price)
                              : 'N/A'
                            : '$0.00'}
                        </div>
                      </Stack>
                    </Col>
                  ) : uc?.category === 'Decoration' ? (
                    <Col className="pb-2 px-1" key={uc?.id}>
                      <Stack direction="vertical" className="text-center">
                        <div className="align-self-center">
                          <Stack direction="horizontal" gap={1}>
                            <Button
                              size="sm"
                              variant="secondary"
                              className="py-0 px-0"
                              style={{ maxHeight: '25px', width: '20px' }}
                              onClick={() => {
                                if (
                                  decoCounters
                                    ?.filter(dc => dc?.id === uc?.id)
                                    ?.at(0)?.counter > 0
                                ) {
                                  updateDecorationCounterArray(uc?.id, -1)
                                }
                              }}
                            >
                              -
                            </Button>
                            <Button
                              size="sm"
                              variant="secondary"
                              className="py-0 px-0"
                              style={{ maxHeight: '25px', width: '20px' }}
                              onClick={() =>
                                updateDecorationCounterArray(uc?.id, 1)
                              }
                            >
                              +
                            </Button>
                          </Stack>
                        </div>
                        <div className={styles.cardListItemLabel + ' pt-1'}>
                          {uc?.category}
                        </div>
                        <div className={styles.cardListItemValue + ' py-1'}>
                          {uc?.name +
                            ' (' +
                            decoCounters?.filter(dc => dc?.id === uc?.id)?.at(0)
                              ?.counter +
                            ')'}
                        </div>
                        <div className={styles.cardListItemValue}>
                          {uc?.price
                            ? '$' +
                              (
                                parseFloat(uc?.price) *
                                decoCounters
                                  ?.filter(dc => dc?.id === uc?.id)
                                  ?.at(0)?.counter
                              ).toFixed(2)
                            : 'N/A'}
                        </div>
                      </Stack>
                    </Col>
                  ) : (
                    <Col className="pb-2 px-1" key={uc?.id}>
                      <Stack direction="vertical" className="text-center">
                        <br />
                        <div className={styles.cardListItemLabel + ' pt-1'}>
                          {uc?.category}
                        </div>
                        <div className={styles.cardListItemValue + ' py-1'}>
                          {uc?.name}
                        </div>
                        <div className={styles.cardListItemValue}>
                          {uc?.price
                            ? '$' + parseFloat(uc?.price).toFixed(2)
                            : 'N/A'}
                        </div>
                      </Stack>
                    </Col>
                  ))
              )}
            <Col className="pb-2 px-1">
              <Stack direction="vertical" className="text-center">
                <br />
                <div className={styles.cardListItemLabel + ' pt-1'}>
                  Subtotal
                </div>
                <div className={styles.cardListItemValue + ' py-1'}>
                  {'$' + getUnitCostTotal().toFixed(2)}
                </div>
              </Stack>
            </Col>
          </Row>
        </ListGroup.Item>
        {/*<ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Tariff</div>
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {data?.tariff ? data?.tariff + '%' : 'N/A'}
            </div>
          </Stack>
        </ListGroup.Item>*/}
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Other cost</div>
            <div className="ms-auto">
              <Stack direction="horizontal" gap={5}>
                <Stack direction="vertical" className="text-center">
                  <div className={styles.cardListItemLabel}>
                    Est. final delivery / shipping
                  </div>
                  <div className={styles.cardListItemValue}>
                    {data?.shippingCost
                      ? '$' + parseFloat(data?.shippingCost).toFixed(2)
                      : 'N/A'}
                  </div>
                </Stack>
                <Stack direction="vertical" className="text-center">
                  <div className={styles.cardListItemLabel}>
                    Sample non-deco
                    <br />5 units
                    <br />
                    (incl. shipping)
                  </div>
                  <div className={styles.cardListItemValue}>
                    {data?.nonDecoSampleCost
                      ? '$' + parseFloat(data?.nonDecoSampleCost).toFixed(2)
                      : 'N/A'}
                  </div>
                </Stack>
                <Stack direction="vertical" className="text-center">
                  <div className={styles.cardListItemLabel}>
                    Sample decorated
                    <br />5 units
                    <br />
                    (incl. shipping)
                  </div>
                  <div className={styles.cardListItemValue}>
                    {data?.decoSampleCost
                      ? '$' + parseFloat(data?.decoSampleCost).toFixed(2)
                      : 'N/A'}
                  </div>
                </Stack>
              </Stack>
            </div>
          </Stack>
          <span className={styles.cardListItemValueTC + ' text-end'}>
            * Suppliers typically send 5pc samples for you to review. If you
            require more than 5pcs, please specify. Additional cost may incur.
          </span>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>
              Quote total
              <span style={{ fontStyle: 'italic' }}>
                {' '}
                = Moq * Unit Cost + Shipping + Non-deco sample + Deco sample
              </span>
            </div>
            <div className={styles.cardListItemValue + ' ms-auto'}>
              ${getQuoteTotalEstimate()}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Other information</div>
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {data?.comments ? data?.comments : 'N/A'}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Last updated</div>
            {/*<div className="vr ms-auto" />*/}
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {moment.unix(data?.updatedOn?.seconds).format('MM/DD/YYYY')}
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction="horizontal" gap={5}>
            <div className={styles.cardListItemLabel}>Expires in</div>
            {/*<div className="vr ms-auto" />*/}
            <div className={styles.cardListItemValue + ' ms-auto'}>
              {getRemainingDays(data?.expiresIn, data?.updatedOn) >= 0
                ? getRemainingDays(data?.expiresIn, data?.updatedOn) + ' Days'
                : 'Expired'}
            </div>
          </Stack>
        </ListGroup.Item>
      </ListGroup>
      {!readOnly && (
        <Card.Body className="text-center">
          <Button
            className={styles.button + ' my-2'}
            disabled={
              selected
                ? true
                : getRemainingDays(data?.expiresIn, data?.updatedOn) >= 0
                  ? false
                  : true
            }
            onClick={handleSelectQuote}
          >
            {selected ? 'Selected' : 'Select'}
          </Button>
          {msg || errorMsg ? (
            <div className="mt-3">
              {errorMsg && (
                <Alert
                  variant="danger"
                  onClose={() => setErrorMsg('')}
                  dismissible
                  className="mb-2"
                >
                  {errorMsg}
                </Alert>
              )}
              {msg && (
                <Alert
                  variant="success"
                  onClose={() => setMsg('')}
                  dismissible
                  className="mb-2"
                >
                  {msg}
                </Alert>
              )}
            </div>
          ) : (
            <></>
          )}
        </Card.Body>
      )}
      {data?.status !== constants.STATUS_QUOTE_DRAFT && ( //&& data?.status !== constants.STATUS_QUOTE_INIT_ASSESSMENT && (
        <Card.Footer>
          <Messages
            title={'Comments'}
            buttonLabel={'Submit'}
            quoteId={data?.id}
            type={constants.TYPE_MSG_QUOTE_COMMENTS}
            numberOfLines={3}
          />
        </Card.Footer>
      )}
    </Card>
  )
}
