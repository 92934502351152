import styles from '../styles/styles.module.css';
import { Container, Row } from 'react-bootstrap';

export const PrivacyPolicy = () => {
    return (
        <Container fluid className={styles.sideMenuBarBackgroundColor + ' py-5 px-5'}>
            <Row className="mb-3">
                <p className={styles.privacyTitle}>Package Maven Privacy Policy</p>
                <p className={styles.privacyContent}>
                    This privacy policy (this “Privacy Policy”) describes the collection of personal information by Package Maven Inc. (“Company,” “we,” or “us”) from users 
                    of our website at www.packagemaven.com along on which a link to this Privacy Policy is displayed (collectively, our “Service”). This Privacy Policy also 
                    describes our use and disclosure of such information. This Privacy Policy also describes your rights as a data subject to inquire about your personal information that we 
                    process and describes certain rights that you, as the data subject, have regarding this information. 
                    Please read this Privacy Policy carefully to understand our practices regarding your personal information and how we will use it.                    
                </p>                        
            </Row>
            <Row className="mb-3">
                <p className={styles.privacyTitleSecondary}>Uses and disclosures of personal information</p>
                <p>
                    <span className={styles.privacyContentBold}>Email</span>
                    <span className={styles.privacyContent}>
                        : By signing up to receive emails from us, we collect your email address and may use it to contact you for purposes including sending 
                        service updates, marketing communications, and customer support. You can opt out of our email communications at any time, and we will not share your email 
                        address with third parties for marketing without your explicit consent. For any questions or concerns regarding email communications or 
                        to update your preferences, please contact us at hello@packagemaven.com.
                    </span>
                </p>
                <p>
                    <span className={styles.privacyContentBold}>Phone number</span>
                    <span className={styles.privacyContent}>
                        : By providing your phone number or opting in to receive phone calls or text messages from us, you consent to receiving communications 
                        for purposes such as service updates, marketing, and customer support. You can opt out at any time, and we will not share your phone number 
                        with third parties for marketing without your explicit consent. Please note that standard message and data rates may apply, and you are 
                        responsible for reviewing any updates to our phone contact practices in this Privacy Policy. For any questions or concerns, contact us at 
                        hello@packagemaven.com.</span>
                </p>
                <p>
                    <span className={styles.privacyContentBold}>User accounts and profiles</span>
                    <span className={styles.privacyContent}>
                        : Our Service gives you the ability to register for a Company account or to create and update a user profile on the applicable Service. 
                        When you sign up for the Service we will collect the personal information that you provide to us in the course of registering for an account 
                        or creating or updating a user profile. This information may include name, postal address, telephone number, email address, your professional 
                        title, your company, and related demographic information about you. We may indicate 
                        that some personal information is required for you to register for the account or to create the profile, while some is optional.</span>
                </p>
            </Row>
        </Container>
    )
}