import styles from '../styles/styles.module.css';
import { useEffect, useState } from "react";
import { Nav, Button, Stack, Badge, Row, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import moment from 'moment';
//import { getFile } from "../services/fileStorageServices";
import { auth } from "../firebase";
import { getUser } from '../services/userServices';
import { getFile } from "../services/fileStorageServices";
import { getSupplierQuotes } from "../services/supplierServices";
import * as constants from "../constants";

function ProjectCard(props) {
  const data = props.data;
  const [buyer, setBuyer] = useState();
  const [buyerLogoImage, setBuyerLogoImage] = useState();
  const [buyerLogoImageLoading, setBuyerLogoImageLoading] = useState(true);
  const [quoteSubmitted, setQuoteSubmitted] = useState(false);
  const [quoteDraftSaved, setQuoteDraftSaved] = useState(false);

  useEffect(() => {    
    async function buyerFetchData() {
      try {
        const response = await getUser(data?.createdBy); 
        setBuyer(response);
      } catch (error) {
        console.log(error);
      } 
    }
    buyerFetchData();
  }, []);

  useEffect(() => {    
    async function buyerLogoFetchData() {
      if (buyer && buyer?.logoFileRefData && buyer?.logoFileRefData?.length > 0) {
        try {
          const response = await getFile(buyer?.logoFileRefData[0].blobName); 
          const base64String = btoa(new Uint8Array(response.data[0].data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          setBuyerLogoImage(base64String);
        } catch (error) {
          console.log(error);
        } 
      }
    }
    buyerLogoFetchData();
  }, [buyer]);

  useEffect(() => {
    if (buyerLogoImage) {
      setBuyerLogoImageLoading(false);
    }
  }, [buyerLogoImage])

  useEffect(() => {
    async function supplierQuoteFetchData() {
      try {
        const response = await getSupplierQuotes(auth?.currentUser?.email, data?.id);
        if (response && response?.length > 0) {
            //setPrevSubmittedQuote(response[0]);
            if (response[0]?.status === constants.STATUS_QUOTE_DRAFT) {
                setQuoteDraftSaved(true);
            } else {
              setQuoteSubmitted(true);
            }
        }
      } catch (error) {
        console.log(error);
      }
    }
    //if (allowQuoteSubmit) {
        supplierQuoteFetchData();
    //}
  }, [])

  return (
    <Card>
      {buyerLogoImageLoading ? (
        <Card.Header>
          <Row className="mx-3 my-4">
            Loading ... 
            <Spinner animation="border" variant="secondary" role="status" className="mx-3">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
        </Card.Header>
      ) : (
        <Card.Img variant="top" src={`data:image/png;base64,${buyerLogoImage}`} height={100} style={{objectFit: "contain"}} className='pt-3 px-3' />
      )}      
      <ListGroup className="list-group-flush border-0">
        <ListGroup.Item className='py-3'>
            <div className={styles.prjCardTitle}>{data?.name}</div>
        </ListGroup.Item>
        <ListGroup.Item className='py-3'>
          <Stack direction="horizontal" gap={3} className='pb-1'>
            <div className={styles.prjCardItemLabel}>Brand</div>
            <div className={styles.prjCardItemValue}>{data?.brand}</div>
          </Stack>
          <Stack direction="horizontal" gap={3} className='pb-1'>
            <div className={styles.prjCardItemLabel}>Type</div>
            <div className={styles.prjCardItemValue}>{data?.base?.type}, {data?.closure?.type}{data?.applicator?.type ? ", " + data?.applicator?.type : ""}</div>
          </Stack>
          <Stack direction="horizontal" gap={3} className='pb-1'>
            <div className={styles.prjCardItemLabel}>Required delivery</div>
            <div className={styles.prjCardItemValue}>{moment(data?.deliveryDate?.toDate()).format("MM/DD/YYYY")}</div>
          </Stack>
          <Stack direction="horizontal" gap={3} className='pb-1'>
            <div className={styles.prjCardItemLabel}>Initial order quantity</div>
            <div className={styles.prjCardItemValue}>{data?.initOrderQuantity}</div>
          </Stack>
          <Stack direction="horizontal" gap={3}>
            <div className={styles.prjCardItemLabel}>Created on</div>
            <div className={styles.prjCardItemValue}>{moment(data?.createdOn?.toDate()).format("MM/DD/YYYY")}</div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item className='py-2'>
          <Stack direction="horizontal" gap={3}>
            <div className={styles.prjCardItemLabel}>Status</div>
            {quoteSubmitted ? (
              <h5 className='pt-2'><Badge bg="primary" text="white">Quoted</Badge></h5>
            ) : (
              quoteDraftSaved ? (
                <h5 className='pt-2'><Badge bg="warning" text="dark">Quote drafted</Badge></h5>
              ) : (
                <h5 className='pt-2'><Badge bg="success" text="white">{data?.status}</Badge></h5>
              )
            )}
          </Stack>
        </ListGroup.Item>
      </ListGroup>
      <Card.Body className="pt-1">
        <Stack direction="vertical" gap={2}>
          <Button className={styles.buttonOutline + " mb-2"} size="sm">
            <Nav.Link as={Link} 
                to={constants.PAGE_PATH_PROJECT_DETAILS} 
                state={JSON.stringify({project: data, quotes: null, allowQuoteSubmit: true, fromPage: constants.PAGE_PATH_BROWSE_PROJECTS})}>
              View details
            </Nav.Link>
          </Button>
        </Stack>        
      </Card.Body>
    </Card>
  );
}
  
export default ProjectCard;