import styles from '../styles/styles.module.css';
import { useEffect, useState, useRef } from "react";
import { Button, Stack, Row, Col, Form, ListGroup, Container } from 'react-bootstrap';
import moment from 'moment';
import { addQuoteMessages, getQuoteMessages, addProjectMessages, getProjectMessages } from "../services/supplierServices";
import * as constants from "../constants";

export default function Messages(props) {
    const projectId = props?.projectId;
    const quoteId = props?.quoteId;
    const commentsType = props?.type;
    const numberOfLines = props?.numberOfLines ? props?.numberOfLines : 4;
    const buttonLabel = props?.buttonLabel ? props?.buttonLabel : "Submit";
    const disabled = props?.disabled ? props?.disabled : false;
    const commentsSubmitBtnRef = useRef();
    const [comments, setComments] = useState("");
    const [prevComments, setPrevComments] = useState([]);
    const [commentsStateChanged, setCommentsStateChanged] = useState(false);

    /*useEffect(() => {
        async function commentsFetchData() {
          try {
            let response;
            if (projectId) {
                response = await getProjectMessages(projectId, commentsType);
            } else if (quoteId) {
                response = await getQuoteMessages(quoteId, commentsType);
            } else  {
                response = ["Error: Invalid Id."];
            }
            setPrevComments(response);
          } catch (error) {
            console.log(error);
          }
        }
        commentsFetchData();
    }, []);*/

    useEffect(() => {
        async function commentsFetchData() {
          try {
            let response;
            if (projectId) {
                response = await getProjectMessages(projectId, commentsType);
            } else if (quoteId) {
                response = await getQuoteMessages(quoteId, commentsType);
            } else  {
                response = ["Error: Invalid Id."];
            }
            setPrevComments(response);
          } catch (error) {
            console.log(error);
          }
        }
        commentsFetchData();
    }, [commentsStateChanged]);

    const handleCommentsSubmit = async (e) => {
        e.preventDefault();
        if (commentsSubmitBtnRef.current && comments.trim() !== "") {
            commentsSubmitBtnRef.current.setAttribute("disabled", "disabled");

            try {
                if (projectId) {
                    addProjectMessages(projectId, commentsType, comments)
                        .then(() => {
                            //setMsg("Comments successfully added.");
                            setCommentsStateChanged(!commentsStateChanged);
                            setComments("");
                    });
                } else if (quoteId) {
                    addQuoteMessages(quoteId, commentsType, comments)
                        .then(() => {
                            //setMsg("Comments successfully added.");
                            setCommentsStateChanged(!commentsStateChanged);
                            setComments("");
                    });
                } else {
                    console.log("Error: Invalid Id");
                }
            } catch {
            //setErrorMsg("Adding comments failed, please try again.");
            }

            commentsSubmitBtnRef.current.removeAttribute("disabled");
        }
    }

    return (
        <>
        <Row>
            <Form.Label className={styles.detailItemLabel}>{props.title}</Form.Label>
        </Row>
        <Row className='mb-3'>
            <Container>
                <ListGroup>
                {(prevComments && prevComments?.length > 0) ? (
                    prevComments?.map(c => (
                    <ListGroup.Item key={"comments" + prevComments.indexOf(c)}>
                        <Row>
                            <Col lg={6}>
                                <span className={styles.cardListItemValue}>{c?.msg}</span>
                            </Col>
                            <Col lg={{span: 5, offset: 1}} className='text-end'>
                                <span className={styles.cardListItemValue}>
                                    {moment.unix(c?.createdOn?.seconds).format("MM/DD/YYYY h:mm A")}
                                </span>
                                <br/>
                                {/*<span className={styles.cardListItemValue}>
                                    {c?.createdBy}
                                </span>*/}
                                <span className={styles.cardListItemValue} 
                                    style={{ color: c?.role?.admin ? "dodgerblue" : (c?.role?.buyer ? "seagreen" : (c?.role?.supplier ? "orange" : "red"))}}>
                                    {c?.role?.admin ? c?.firstName + " (Package Maven)" : 
                                    (c?.role?.buyer ? c?.firstName + " (Buyer)" : 
                                    (c?.role?.supplier ? c?.firstName + " (Supplier)" : "N/A"))}
                                </span>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    ))
                    ) : (
                    <ListGroup.Item>
                        <span className={styles.cardListItemValue}>No messages yet.</span>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Container>
        </Row>
        <Row>
            <Form.Group className="mb-2" controlId="comments.control"> 
                <Form.Control as="textarea" onChange={e => setComments(e.target.value)} rows={numberOfLines} value={comments} disabled={disabled} />
            </Form.Group>
            <div className='text-end'>
                <Button ref={commentsSubmitBtnRef} size='sm' className={styles.buttonOutline} onClick={handleCommentsSubmit} disabled={disabled}>{buttonLabel}</Button>
            </div>            
        </Row>
        </>
    )
}