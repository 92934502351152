import styles from '../styles/styles.module.css';
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, Stack, Button, Form, Spinner, Accordion, Image } from 'react-bootstrap';
import { setEngagedQuoteStatus, getEngagedQuoteStatus } from "../services/buyerServices";
import Messages from './Messages';
import * as constants from "../constants";
import iconMessage from '../images/icons/communication.png';

export default function StepBuyerPurchaseOrder(props) {
    const location = useLocation();
    const stateObj = JSON.parse(location?.state);
    //const projectId = stateObj?.projectId;
    const quoteData = props?.quoteData;
    
    // Toast 
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    
    const [toastSuccessShow, setToastSuccessShow] = useState(false);
    const [toastErrorShow, setToastErrorShow] = useState(false);

    return (
        <Container className='px-0'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h4>Purchase order</h4>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='px-2 pb-2'>
                        </Row>
                        <Row className='px-2 pt-2'>
                            <Card className='px-0'>
                                <Card.Title className='px-3 pt-3 pb-2'>
                                    <Image src={iconMessage} />
                                    <span className='ps-3'>Ask questions</span>
                                </Card.Title>
                                <Card.Body className='pt-2'>
                                    <Messages 
                                        title={"Please submit any comments or questions."} 
                                        buttonLabel={"Submit"}
                                        quoteId={quoteData?.id} 
                                        type={constants.TYPE_MSG_QUOTE_DESIGN_REVIEW}
                                        numberOfLines={3}
                                    />
                                </Card.Body>
                            </Card>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}