import { Container, Modal, Row, Spinner } from 'react-bootstrap';
import { auth } from "../firebase";
import { getUser } from '../services/userServices';
import { useEffect, useState } from 'react';
import { SupplierProfile } from '../components/SupplierProfile';
import { BuyerProfile } from '../components/BuyerProfile';
import * as constants from "../constants";

export const RegisterProfile = () => {
    const [userData, setUserData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function regProfileFetchData() {
            try {
                const response = await getUser(auth?.currentUser?.email);
                setUserData(response);
            } catch (error) {
                console.log(error);
            }
        }
        regProfileFetchData();
    }, []);

    useEffect(() => {
        if (userData && userData?.role) {
            setLoading(false);
        }
    }, [userData, userData?.role]);

    return (
        <>
            <Container fluid>
                <Modal show={true} fullscreen={true}>
                    <Modal.Header className='py-2'>
                        <Modal.Title>Profile registration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Row className="mx-3 my-3">
                                Loading ...
                                <Spinner animation="border" variant="secondary" role="status" className="mx-3">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Row>
                        ) : (
                            userData && userData?.role && (
                                (userData?.role?.supplier ? (<SupplierProfile navigateTo={constants.PAGE_PATH_BROWSE_PROJECTS} />) : (
                                    userData?.role?.buyer ? (<BuyerProfile navigateTo={constants.PAGE_PATH_MY_PROJECTS} />) : (
                                        userData?.role?.admin ? (<SupplierProfile navigateTo={constants.PAGE_PATH_BROWSE_PROJECTS} />) : (
                                            <Row className="mx-3 my-3">
                                                Please refresh your page
                                            </Row>
                                        ))))
                            ))}
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    )
}