import { Navigate, Outlet, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth"

const AuthRoute = () => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return (
      <div className="loading-component">
        <p>Loading...</p>
      </div>
    )
  }

  return user ? (
      <Outlet />
  ) : (
      <Navigate to={"/login"} replace state={{ path: location.pathname }} />
  );
};

export default AuthRoute;