import styles from '../styles/styles.module.css';
import { useRef, useState } from "react";
import { Container, Row, Col, Alert, Button, Card, Form } from "react-bootstrap";
import { auth } from "../firebase";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

export const UpdatePassword = (props) => {
    const currentPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmNewPasswordRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMsg("");
        setErrorMsg("");
        setLoading(true);
        if (!currentPasswordRef.current?.value || !newPasswordRef.current?.value || !confirmNewPasswordRef.current?.value) {
            setErrorMsg("Please fill all the fields");
            setLoading(false);
            return;
        }
        if (newPasswordRef.current.value !== confirmNewPasswordRef.current.value) {
            setErrorMsg("New passwords don't match.");
            setLoading(false);
            return;
        }

        const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPasswordRef.current.value);

        reauthenticateWithCredential(auth.currentUser, credential)
            .then(() => {
                updatePassword(auth.currentUser, newPasswordRef.current.value)
                    .then(() => {
                        setMsg("Password updated successfully.")
                    }).catch((error) => {
                        const errorMessage = error.message;
                        setErrorMsg(errorMessage.replace("Firebase: ", "Mew password: "));
                    });
            }).catch((error) => {
                setErrorMsg("Current password is incorrect.");
            })

        setLoading(false);
    };

    return (
        <Container fluid className={styles.sideMenuBarBackgroundColor}>
            <Row className="justify-content-center py-5">
                <Col sm={12} md={8}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Update password</h2>
                            <Form onSubmit={handleSubmit} className='px-3'>
                                <Form.Group className="mb-3" id="password">
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control type="password" ref={currentPasswordRef} required />
                                </Form.Group>
                                <Form.Group className="mb-3" id="password">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type="password" ref={newPasswordRef} required />
                                </Form.Group>
                                <Form.Group className="mb-3" id="confirm-password">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control type="password" ref={confirmNewPasswordRef} required />
                                </Form.Group>
                                {msg && (
                                    <Alert
                                        variant="success"
                                        onClose={() => setErrorMsg("")}
                                        dismissible
                                        className="my-3">
                                        {msg}
                                    </Alert>
                                )}
                                {errorMsg && (
                                    <Alert
                                        variant="danger"
                                        onClose={() => setErrorMsg("")}
                                        dismissible
                                        className="my-3">
                                        {errorMsg}
                                    </Alert>
                                )}
                                <div className="text-center mt-3">
                                    <Button disabled={loading} type="submit" className={styles.button + " w-50 my-2"}>
                                        Update
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};