import styles from '../styles/styles.module.css';
import { Nav, Navbar, Container, Image, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { getUser } from '../services/userServices';
import { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth"

import imgLogo from '../images/logo_black.png';

export const NavigationBar = () => {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [userData, setUserData] = useState();

    useEffect(() => {
        async function navBarFetchData() {
            try{
                const response = await getUser(user?.email);
                setUserData(response);
            } catch (error) {
                console.log(error);
            }
        }
        navBarFetchData();
    }, [user]);

    useEffect(() => {
        if (userData) {
            if (!userData?.isProfileComplete) {
                navigate("/registerProfile");
            }
        }
    }, [userData, navigate]);

    if (loading) {
        return (
          <div className="loading-component">
            <p>Loading...</p>
          </div>
        )
    }

    return (
        <>
            <Navbar collapseOnSelect expand="sm" className={styles.sideMenuBarBackgroundColor + ' pt-4 px-3'}>
                <Container fluid className='mx-0'>
                    <Navbar.Brand className="navbar-brand pe-3">
                        <Nav.Link as={Link} to="/">
                            <Image src={imgLogo} fluid width={200} />
                        </Nav.Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav' className='mb-2' />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/*<Nav variant="underline" className='me-auto'>
                            {user && (userData?.role?.supplier || userData?.role?.admin) && (                           
                                <Nav.Link className="navbarButton me-3" as={Link} to={constants.PAGE_PATH_BROWSE_PROJECTS}>
                                    Browse Projects
                                </Nav.Link>
                            )}
                            {user && (userData?.role?.buyer || userData?.role?.admin) && (                           
                                <Nav.Link className="navbarButton me-3" as={Link} to={constants.PAGE_PATH_MY_PROJECTS}>
                                    My Projects
                                </Nav.Link>
                            )}
                            {user && (userData?.role?.supplier || userData?.role?.admin) && (
                                <Nav.Link className="navbarButton me-3" as={Link} to={constants.PAGE_PATH_MY_QUOTES}>
                                    My Quotes
                                </Nav.Link>
                            )}
                            {user && (userData?.role?.admin) && (
                                <Nav.Link className="navbarButton me-3" as={Link} to={constants.PAGE_PATH_INIT_ASSESSMENT}>
                                    Initial Assessment
                                </Nav.Link>
                            )}
                        </Nav>*/}
                        <Nav className='ms-auto justify-content-end'>
                            {!user && (
                                <Button className={styles.button + " px-3 py-0"} size="sm">
                                    <Nav.Link as={Link} to="/login">
                                        <span className={styles.buttonLabel} style={{ color: "#FFFFFF"}}>Login</span>
                                    </Nav.Link>
                                </Button>
                            )}
                            {/*{!user && (
                                <Button className={styles.buttonOutline + " px-4 ms-4"} size="sm">
                                    <Nav.Link as={Link} to="/register">
                                    <span className={styles.buttonLabel}>Register</span>
                                    </Nav.Link>
                                </Button>
                            )}*/}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};