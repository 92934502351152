import styles from '../styles/styles.module.css';
import { useEffect, useState, useRef } from "react";
import { Button, Stack, Alert, Row, Col, Card, ListGroup, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth"
import { getUser } from "../services/userServices";
import { updateQuoteStatus, updateProjectStatus } from "../services/buyerServices";
import { getFile } from "../services/fileStorageServices";
//import ImageGallery from "react-image-gallery";
import Messages from "./Messages";
import * as constants from "../constants";
import { FileGallery } from './FileGallery';

export default function QuoteCard(props) {
  const readOnly = props?.readOnly;
  const projectId = props?.projectId;
  const data = props?.quoteData;

  const [user, userLoading] = useAuthState(auth);
  const [userData, setUserData] = useState();

  const [supplier, setSupplier] = useState();

  //const designTechDrawingFileRefData = data?.designTechDrawingFileRefData;
  //const [designTechDrawingFiles, setDesignTechDrawingFiles] = useState([]);
  //const [designTechDrawingFilesLoading, setDesignTechDrawingFilesLoading] = useState(true);

  //const componentPictureFileRefData = data?.componentPictureFileRefData;
  //const [componentPictureFiles, setComponentPictureFiles] = useState([]);
  //const [componentPictureFilesLoading, setComponentPictureFilesLoading] = useState(true);

  const [finishOns, setFinishOns] = useState([]);
  const [decoCounters, setDecoCounters] = useState([]);

  const [selected, setSelected] = useState((data?.status === constants.STATUS_QUOTE_SUBMITTED || data?.status === constants.STATUS_QUOTE_DRAFT) ? false : true);// || data?.status === constants.STATUS_QUOTE_INIT_ASSESSMENT) ? false : true);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    async function userFetchData() {
        try{
            const response = await getUser(user?.email);
            setUserData(response);
        } catch (error) {
            console.log(error);
        }
    }
    userFetchData();
}, [user]);

  /*useEffect(() => {    
    async function filesFetchData() {
      try {
        if (designTechDrawingFileRefData) {
          let dtdFiles = [];
          for (const data of designTechDrawingFileRefData) {
            const response = await getFile(data?.blobName);
            const fileExt = data?.blobName?.substring(data?.blobName?.lastIndexOf(".") + 1);
            const file = response.data[0].data;
            const base64String = btoa(new Uint8Array(file).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            const fileObj = {
                //original: `data:image/png;base64,${base64String}`,
                //originalHeight: '450',
                //thumbnail: `data:image/png;base64,${base64String}`,
                //thumnailHeight: '50',
                //fullscreen: `data:image/png;base64,${base64String}`,
                base64String: base64String,
                pdfFile: `data:application/pdf;base64,${base64String}`,
                imgFile: `data:image/png;base64,${base64String}`,
                fileExt: fileExt,
            }
            dtdFiles.push(fileObj);
          };
          setDesignTechDrawingFiles(dtdFiles);
        }
      } catch (error) {
        console.log(error);
      } 
    }
    filesFetchData();
  }, [designTechDrawingFileRefData]);*/

  /*useEffect(() => {
    if (!designTechDrawingFiles || designTechDrawingFiles?.length === 0) {
      setDesignTechDrawingFilesLoading(true);
    } else {
      setDesignTechDrawingFilesLoading(false);
    }
  }, [designTechDrawingFiles]);*/

  /*useEffect(() => {    
    async function filesFetchData() {
      try {
        if (componentPictureFileRefData) {
            let cpFiles = [];
            for (const data of componentPictureFileRefData) {
                const response = await getFile(data.blobName); 
                const fileExt = data?.blobName?.substring(data?.blobName?.lastIndexOf(".") + 1)?.toLowerCase();
                const file = response.data[0].data;
                const base64String = btoa(new Uint8Array(file).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                const fileObj = {
                    //original: `data:image/png;base64,${base64String}`,
                    //originalHeight: '450',
                    //thumbnail: `data:image/png;base64,${base64String}`,
                    //thumnailHeight: '50',
                    //fullscreen: `data:image/png;base64,${base64String}`,
                    base64String: base64String,
                    fileExt: fileExt,
                }
                cpFiles.push(fileObj);
            };
            setComponentPictureFiles(cpFiles);
        }
      } catch (error) {
        console.log(error);
      } 
    }
    filesFetchData();
  }, [componentPictureFileRefData]);*/

  /*useEffect(() => {
    if (!componentPictureFiles || componentPictureFiles?.length === 0) {
      setComponentPictureFilesLoading(true);
    } else {
      setComponentPictureFilesLoading(false);
    }
  }, [componentPictureFiles]);*/

  useEffect(() => {    
    setFinishOns(getInitialFinishOnObject(data));
    setDecoCounters(getInitialDecorationCounterObject(data));
  }, [data]);

  function getInitialFinishOnObject(data) {
    let results = [];
    const finishObjects = data?.unitCost?.filter(uc => uc?.category === "Finish");

    for (let i=0; i<finishObjects?.length; i++) {
      results.push({ ...finishObjects[i], onOffOption: true });
    }
    return results;
  }

  function updateFinishOnArray(id, option) {
    let tempData = finishOns;
    for (let obj of tempData) {
      if (obj?.id === id) {
        let newObj = { ...obj, onOffOption: option};
        //console.log("newObj", newObj);
        tempData[tempData.indexOf(obj)] = newObj;
      }
    }
    setFinishOns([...tempData]);
  }

  function getInitialDecorationCounterObject(data) {
    let results = [];
    const decoObjects = data?.unitCost?.filter(uc => uc?.category === "Decoration");

    for (let i=0; i<decoObjects?.length; i++) {
      results.push({ ...decoObjects[i], counter: 1 });
    }
    return results;
  }

  function updateDecorationCounterArray(id, option) {
    let tempData = decoCounters;
    for (let obj of tempData) {
      if (obj?.id === id) {
        let newObj = { ...obj, counter: obj?.counter + option};
        //console.log("newObj", newObj);
        tempData[tempData.indexOf(obj)] = newObj;
      }
    }
    setDecoCounters([...tempData]);
  }

  // this function returns the number of days remaining before expiration
  function getRemainingDays(expiresInValue, submittedOn) {
    var results;
    results =
        Math.floor(
            expiresInValue - 
            moment.duration(
                moment()
                .diff(
                    moment.unix(submittedOn?.seconds))).asDays()
                );
    return results;
  }

  useEffect(() => {    
    async function supplierFetchData() {
      try {
        const response = await getUser(data?.createdBy); 
        setSupplier(response);
      } catch (error) {
        console.log(error);
      } 
    }
    supplierFetchData();
  }, [data]);

  const handleSelectQuote = async (e) => {
    e.preventDefault();
    try {        
      updateProjectStatus(projectId, constants.STATUS_PROJECT_SUPPLIER_SELECTED)
            .then(() => {
                try {        
                  updateQuoteStatus(data?.id, constants.STATUS_QUOTE_SELECTED)
                        .then(() => {
                            setSelected(true);
                            //setMsg("Selecting quote successful.");
                            props.sendToParentQuoteSelected();
                        });
                } catch {
                    setErrorMsg("Selecting quote failed, please try again.");
                }
            });
    } catch {
        setErrorMsg("Updating project status failed, please try again.");
    }
  }

  function getUnitCostTotal() {
    let unitCostSum = 0;
    if (data?.unitCost) {
      for (const obj of data?.unitCost) {
        if (obj?.price !== "") {
            if (obj?.category === "Finish") {
              if (finishOns?.filter(fo => fo?.id === obj?.id)?.at(0)?.onOffOption) {
                unitCostSum = unitCostSum + parseFloat(obj?.price);
              }              
            } else if (obj?.category === "Decoration") {
              unitCostSum = unitCostSum + (decoCounters?.filter(dc => dc?.id === obj?.id)?.at(0)?.counter * parseFloat(obj?.price));
            } else {
              unitCostSum = unitCostSum + parseFloat(obj?.price);
            }
        }
      }
    }
    return unitCostSum;
  }

  function getQuoteTotalEstimate() {
    const results = 
      getUnitCostTotal()
      *
      parseFloat(data?.moq)
      +
      (data?.shippingCost ? parseFloat(data?.shippingCost) : 0)
      +
      (data?.nonDecoSampleCost ? parseFloat(data?.nonDecoSampleCost) : 0)
      +
      (data?.decoSampleCost ? parseFloat(data?.decoSampleCost) : 0);

    return results.toFixed(2);
  }

  return (
    <Card>
      {/*<Card.ImgOverlay className="position-relative">
        <Stack direction="horizontal" gap={3}>
          <div>
              <Badge pill bg="secondary">Quotes {data.quotes.length}</Badge>
          </div>
          <div className="ms-auto">
            {data?.quotes?.findIndex(quote => quote?.createdBy === auth?.currentUser?.email) !== -1 ? <Badge pill bg="danger">Quoted</Badge> : <></>}
          </div>
        </Stack>
      </Card.ImgOverlay>*/}
      {/*<Card.Img variant="top" src={`data:image/png;base64,${previewImage}`} height={200} />*/}
      {user && (userData?.role?.admin) && (
        <Card.Header>
          <Alert variant="danger" className='mt-2 my-2'>
              <Stack direction="horizontal" gap={3}>
                  <h5 className='my-0'>Admin only</h5>
                  <div className={styles.cardListItemLabel}>Author</div>
                  <div className={styles.cardListItemValue}>{supplier?.firstName + " " + supplier?.lastName + " (" + supplier?.email + ")"}</div>
              </Stack>
          </Alert>
        </Card.Header>
      )}    
      <ListGroup className="list-group-flush" horizontal>         
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>Supplier</div>
            <hr className='my-2'/>
            <div className={styles.cardListItemValue}>{supplier?.company}</div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>Location</div>
            <hr className='my-2'/>
            <div className={styles.cardListItemValue}>{data?.shippingLocation ? data?.shippingLocation : "N/A"}</div>
          </Stack>
        </ListGroup.Item>
        {/*<ListGroup.Item className='text-center' style={{ width: "10%"}}>
          <Stack direction="vertical" gap={3}>
            <div className={styles.cardListItemLabel}>Timeline</div>
            <div>
              <Stack direction="vertical" gap={2}>
                <Stack direction="vertical" className='text-center'>
                  <div className={styles.cardListItemLabel}>Production</div>
                  <div className={styles.cardListItemValue}>{data?.productionTimeline ? data?.productionTimeline + " Weeks" : "N/A"}</div>
                </Stack>
                <Stack direction="vertical" className='text-center'>
                  <div className={styles.cardListItemLabel}>Shipping</div>
                  <div className={styles.cardListItemValue}>{data?.shippingTimeline ? data?.shippingTimeline + " Weeks" : "N/A"}</div>
                </Stack>
              </Stack>
            </div>
          </Stack>
        </ListGroup.Item>*/}
        {/*<ListGroup.Item className='text-center' style={{ maxWidth: "10%"}}>
          <Row>
            <Stack direction="vertical" gap={0}>
              <div className={styles.cardListItemLabel}>Technical drawings</div>
              <div className="ms-0">
                  <Row className='py-0'>
                    {designTechDrawingFilesLoading ? (
                      <Row className="mx-1 my-1">
                          Loading ... 
                          <Spinner animation="border" variant="secondary" role="status" className="mx-3">
                              <span className="visually-hidden">Loading...</span>
                          </Spinner>
                      </Row>
                    ) : (
                      <FileGallery files={designTechDrawingFiles} />
                    )}
                  </Row>
              </div>
            </Stack>
          </Row>
        </ListGroup.Item>*/}
        {/*<ListGroup.Item className='text-center' style={{ maxWidth: "10%"}}>
          <Row>
            <Stack direction="vertical" gap={0}>
              <div className={styles.cardListItemLabel}>Component pictures</div>
              <div className="ms-0">
                  <Row className='py-0'>
                    {componentPictureFilesLoading ? (
                      <Row className="mx-1 my-1">
                          Loading ... 
                          <Spinner animation="border" variant="secondary" role="status" className="mx-3">
                              <span className="visually-hidden">Loading...</span>
                          </Spinner>
                      </Row>
                    ) : (
                      <FileGallery files={componentPictureFiles} />
                    )}
                  </Row>
              </div>
            </Stack>
          </Row>
        </ListGroup.Item>*/}
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>MOQ</div>
            <hr className='my-2'/>
            <div className={styles.cardListItemValue}>{data?.moq ? data?.moq : "N/A"}</div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>Unit cost</div>
            <hr className='my-2'/>
            <div className={styles.cardListItemValue + ' py-0'}>{"$" + getUnitCostTotal().toFixed(2)}</div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>Other cost</div>
            <hr className='my-2'/>
            <div>
              <Stack direction="vertical" gap={2}>
                <Stack direction="vertical" className='text-center'>
                  <div className={styles.cardListItemLabel}>Final delivery</div>
                  <div className={styles.cardListItemValue}>{data?.shippingCost ? "$" + parseFloat(data?.shippingCost).toFixed(0) : "N/A"}</div>
                </Stack>
                <Stack direction="vertical" className='text-center'>
                  <div className={styles.cardListItemLabel}>Samples</div>
                  <div className={styles.cardListItemValue}>
                    {data?.nonDecoSampleCost ? "$" + parseFloat(data?.nonDecoSampleCost).toFixed(0) : "N/A"}{', '}
                    {data?.decoSampleCost ? "$" + parseFloat(data?.decoSampleCost).toFixed(0) : "N/A"}
                  </div>
                </Stack>
              </Stack>
            </div>
          </Stack>
          {/*<span className={styles.cardListItemValueTC + " text-end"}>
            * Suppliers typically send 5pc samples for you to review. If you require more than 5pcs, please specify. Additional cost may incur.
          </span>*/}
        </ListGroup.Item>
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>Quote total</div>
            <hr className='my-2'/>
            <div className={styles.cardListItemValue}>${getQuoteTotalEstimate()}</div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item className='text-center' style={{ width: "15%"}}>
          <Stack direction="vertical" gap={0}>
            <div className={styles.cardListItemLabel}>Actions</div>
            <hr className='my-2'/>
            <Button className={styles.buttonOutline + " mb-2"} size="sm">
              <Nav.Link as={Link} 
                      to={constants.PAGE_PATH_QUOTE_DETAILS} 
                      state={JSON.stringify({data: data})}>
                  Details
              </Nav.Link>
            </Button>
            <Button 
              size='sm'
              className={styles.button + " mb-2"}
              disabled={selected ? true : getRemainingDays(data?.expiresIn, data?.updatedOn) >= 0 ? false : true}
              onClick={handleSelectQuote}
            >
              { selected ? ("Selected") : ("Select")}
            </Button>
            {msg || errorMsg ? 
              (<div className="mt-3">
                  {errorMsg && (
                      <Alert
                          variant="danger"
                          onClose={() => setErrorMsg("")}
                          dismissible
                          className="mb-2">
                          {errorMsg}
                      </Alert>
                  )}
                  {msg && (
                      <Alert
                          variant="success" 
                          onClose={() => setMsg("")} 
                          dismissible 
                          className="mb-2">
                          {msg}
                      </Alert>
                  )}
              </div>
              ) : (
                <>
                </>
            )}
          </Stack>
        </ListGroup.Item>
      </ListGroup>
      {/*{data?.status !== constants.STATUS_QUOTE_DRAFT && (//&& data?.status !== constants.STATUS_QUOTE_INIT_ASSESSMENT && (
      <Card.Footer>
        <Messages 
          title={"Comments"} 
          buttonLabel={"Submit"}
          quoteId={data?.id} 
          type={constants.TYPE_MSG_QUOTE_COMMENTS}
          numberOfLines={3} />
      </Card.Footer>
      )}*/}
    </Card>
  );
}