// page paths
export const PAGE_PATH_HOME = "/home";
export const PAGE_PATH_BROWSE_PROJECTS = "/browseProjects";
export const PAGE_PATH_MY_PROJECTS = "/myProjects";
export const PAGE_PATH_MY_QUOTES = "/myQuotes";
export const PAGE_PATH_PROJECT_PROGRESS_BUYER = "/projectProgressBuyer";
export const PAGE_PATH_PROJECT_PROGRESS_SUPPLIER = "/projectProgressSupplier";
export const PAGE_PATH_PROJECT_UPLOAD = "/projectUpload";
export const PAGE_PATH_PROJECT_EDIT = "/projectEdit";
export const PAGE_PATH_ENGAGED_PROEJECT_BUYER_VIEW= "/engagedProjectBV";
export const PAGE_PATH_ENGAGED_PROEJECT_SUPPLIER_VIEW= "/engagedProjectSV";
export const PAGE_PATH_QUOTES_LIST = "/quotesList";
export const PAGE_PATH_PROJECT_DETAILS = "/projectDetails";
export const PAGE_PATH_QUOTE_DETAILS = "/quoteDetails";
export const PAGE_PATH_INIT_ASSESSMENT = "/initAssessment";
export const PAGE_PATH_REGISTER_PROFILE = "/registerProfile";
export const PAGE_PATH_PRIVACY_POLICY = "/privacyPolicy";
export const PAGE_PATH_REGISTER = "/register";
export const PAGE_PATH_LOGIN = "/login";
export const PAGE_PATH_UPDATE_PASSWORD = "/updatePassword";

// project status
export const STATUS_PROJECT_DRAFT = "Draft project"; // when project is created by buyer, before submit for supplier quotes
export const STATUS_PROJECT_INIT_ASSESSMENT = "Initial assessment"; // when project is submitted for Package Maven review
export const STATUS_PROJECT_ACCEPTING_QUOTES = "Accepting quotes"; // when project is submiited for quotes by buyer, waiting for supplier quotes
export const STATUS_PROJECT_SUPPLIER_SELECTED = "Supplier selected"; // when buyer completes quote selection 
export const STATUS_PROJECT_CANCELLED = "Cancelled"; // when project is cancelled/closed by buyer after receiving supplier quotes and before completion 
export const STATUS_PROJECT_COMPLETE = "Complete"; // when project is complete with delivery of products
export const STATUS_PROJECT_REOPENED = "Reopened"; // when project was cancelled and buyer reopens the project, treat it same as "draft" status

// quote status 
export const STATUS_QUOTE_DRAFT = "Draft quote";
export const STATUS_QUOTE_INIT_ASSESSMENT = "Initial assessment"; // currently not used, quote goes to submitted status when supplier submits
export const STATUS_QUOTE_SUBMITTED = "Quote submitted"; // after review is complete, buyers can view submitted quotes
export const STATUS_QUOTE_SELECTED = "Quote selected";
export const STATUS_QUOTE_ADJUSTED = "Quote adjusted"; // updated quote after sample feedback from buyer during non-deco and pps steps
export const STATUS_QUOTE_ADJUSTED_NOT_SELECTED = "Quote not selected during adjustment";
export const STATUS_QUOTE_COMPLETE = "Complete";
export const STATUS_QUOTE_CANCELED = "Canceled";

// engaged quote status
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED = "Non-deco sample requested";
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT = "Non-deco sample in transit";
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED = "Non-deco sample delivered";
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUST = "Non-deco sample adjustment needed";
//export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_FEEDBACK_SUBMITTED = "Non deco sample feedback submitted";
export const STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE = "Non-deco sample complete";

export const STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW = "Design submitted for review";
export const STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE = "Design review complete";

export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_REQUESTED = "Pre-prod sample requested";
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT = "Pre-prod sample in transit";
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED = "Pre-prod sample delivered";
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUST = "Pre-prod sample adjustment needed";
//export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_FEEDBACK_SUBMITTED = "Pre-prod sample feedback submitted";
export const STATUS_ENGAGED_QUOTE_SAMPLE_DECO_COMPLETE = "Pre-prod sample complete";

// message type
export const TYPE_MSG_PRJ_INIT_ASESSMENT = "Project initial assessment";
export const TYPE_MSG_QUOTE_COMMENTS = "Quote comments";
export const TYPE_MSG_QUOTE_SAMPLE_NON_DECO_REQUEST = "Sample non-deco request";
export const TYPE_MSG_QUOTE_SAMPLE_NON_DECO_FEEDBACK = "Sample non-deco feedback";
export const TYPE_MSG_QUOTE_DESIGN_REVIEW = "Design review feedback";
export const TYPE_MSG_QUOTE_SAMPLE_DECO_REQUEST = "Sample deco request";
export const TYPE_MSG_QUOTE_SAMPLE_DECO_FEEDBACK = "Sample deco feedback";