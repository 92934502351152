import styles from '../styles/styles.module.css';
import { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Stack, Button, Accordion, Toast, ToastContainer, Image, Alert, Form, Modal } from 'react-bootstrap';
import { getEngagedQuoteStatus } from "../services/buyerServices";
import Messages from './Messages';
import * as constants from "../constants";
import iconMessage from '../images/icons/communication.png';
import ProgressBarSample from './ProgressBarSample';
import { FormSampleSent } from './FormSampleSent';
import { StepProgress } from 'react-stepz';
import iconInstruction from '../images/icons/instruction.png';

export default function StepSupplierSampleNonDeco(props) {
    //const projectData = props?.projectData;
    const quoteData = props?.quoteData;

    const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState(null);
    const [currentStepSample, setCurrentStepSample] = useState(-1);
    const [stateChanged, setStateChanged] = useState(false);

    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const sampleSentBtnRef = useRef();
    //const deliveredBtnRef = useRef();
    //const requestAdjBtnRef = useRef();
    //const completeBtnRef = useRef();

    const [sampleProgressAlertMsg, setSampleProgressAlertMsg] = useState();

    // Toast 
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const [toastSuccessShow, setToastSuccessShow] = useState(false);
    const [toastErrorShow, setToastErrorShow] = useState(false);

    // Modal
    const [showSampleSentModal, setShowSampleSentModal] = useState(false);

    useEffect(() => {
        async function engagedQuoteFetchData() {
            try {
                const response = await getEngagedQuoteStatus(quoteData?.id);
                setEngagedQuoteStatusHistory(response);
            } catch (error) {
                console.log(error);
            }
        }
        engagedQuoteFetchData();
    }, [quoteData, stateChanged]);

    useEffect(() => {
        if (engagedQuoteStatusHistory) {
            // set current step for progress bar
            if (engagedQuoteStatusHistory?.length > 0) {
                if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED) {
                    setCurrentStepSample(1);
                    setSampleProgressAlertMsg("Buyer requested 5 units of samples.");
                } else if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT) {
                    setCurrentStepSample(2);
                    setSampleProgressAlertMsg("Samples are on their way to the buyer.");
                } else if (engagedQuoteStatusHistory[0]?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED) {
                    setCurrentStepSample(3);
                    setSampleProgressAlertMsg("Buyer received the samples. Please look out for any feedback.");
                } else {
                    setCurrentStepSample(-1); // invalid state
                }
            } else {
                setCurrentStepSample(0);
                setSampleProgressAlertMsg("Buyer is preparing for a sample request.");
            }

            // set delivery address 
            if (engagedQuoteStatusHistory?.length > 0) {
                //const lastIndex = engagedQuoteStatusHistory?.length - 1;
                const sampleReq = engagedQuoteStatusHistory?.filter(h => h?.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED);
                setAddress1(sampleReq[0]?.values?.deliveryAddress?.address1);
                setAddress2(sampleReq[0]?.values?.deliveryAddress?.address2);
                setCity(sampleReq[0]?.values?.deliveryAddress?.city);
                setState(sampleReq[0]?.values?.deliveryAddress?.state);
                setZip(sampleReq[0]?.values?.deliveryAddress?.zip);
            }
        }
    }, [engagedQuoteStatusHistory]);

    /*const onNonDecoSampleDeliveredSubmit = async (e) => {
        e.preventDefault();
        if (deliveredBtnRef.current) {
            deliveredBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED, null)
                    .then(() => {
                        setMsg("Confirmed sample delivery successfully.");
                        setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                    });
            } catch {
                setErrorMsg("Confirming sample delivery failed, please try again.");
                setToastErrorShow(true);
            }

            deliveredBtnRef.current.removeAttribute("disabled");
        }
    }*/

    /*const onNonDecoSampleAdjustSubmit = async (e) => {
        e.preventDefault();
        if (requestAdjBtnRef.current) {
            requestAdjBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUST, null)
                    .then(() => {
                        setMsg("Sample adjustment request submitted successfully.");
                        setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                    });
            } catch {
                setErrorMsg("Submitting sample adjustment request failed, please try again.");
                setToastErrorShow(true);
            }

            requestAdjBtnRef.current.removeAttribute("disabled");
        }
    }*/

    /*const onNonDecoSampleCompleteSubmit = async (e) => {
        e.preventDefault();
        if (completeBtnRef.current) {
            completeBtnRef.current.setAttribute("disabled", "disabled");

            try {
                setEngagedQuoteStatus(quoteData?.id, constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE, null)
                    .then(() => {
                        setMsg("Completed non-deco sample step successfully.");
                        setToastSuccessShow(true);
                        setStateChanged(!stateChanged);
                        props.sendToParentStepCompleted();
                    });
            } catch {
                setErrorMsg("Completing non-deco sample step failed, please try again.");
                setToastErrorShow(true);
            }

            completeBtnRef.current.removeAttribute("disabled");
        }
    }*/
    const handleSampleSent = (e) => {
        //window.scrollTo(0, 0);
        setStateChanged(!stateChanged);
        setMsg("Tracking number recorded.");
        //setToastSuccessShow(true);
        setShowSampleSentModal(false);
    }

    return (
        <Container fluid={true}>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h3>Non decorated sample cost</h3>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='px-2 pb-2'>
                            <Row>
                                <div className={styles.accordContent}>
                                    <span>5 units of sample:</span>
                                    <span className={"ms-2"}>{quoteData?.nonDecoSampleCost ? "$" + parseFloat(quoteData?.nonDecoSampleCost).toFixed(2) : "N/A"}</span>
                                </div>
                                <div className={styles.cardListItemValueTC + " pt-3"}>
                                    Sample costs are waived if the final package production order is processed with you.
                                    <br />
                                    If the final package production order is not processed with you (i.e. different supplier is selected at a later stage), the sample cost will be invoiced to the buyer.
                                </div>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h3>Sample tracker</h3>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='px-2 justify-content-md-center'>
                            <Col className='pb-3'>
                                <Alert className={styles.instructionBackgroundColor + ' my-2'}>
                                    <Row className='className="justify-content-md-center py-2'>
                                        <Col xs={2} lg={2} className='text-end'>
                                            <div className='mb-2'>
                                                <Image src={iconInstruction} />
                                            </div>
                                        </Col>
                                        <Col xs={10} lg={9}>
                                            <p style={{ fontWeight: 'bold' }}>What's next?</p>
                                            <p>{sampleProgressAlertMsg}</p>
                                            {/*<p>
                                                <span style={{ fontWeight: 'bold'}}>Step 1.</span> Submit your request. 
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 2.</span> Supplier receives your request.
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 3.</span> Supplier ships the request samples.
                                                <br/>
                                                <span style={{ fontWeight: 'bold'}}>Step 4.</span> Samples are delivered for your review.
                                            </p>*/}
                                            <p className='mb-0'>If you have any questions in the meantime, please leave a message in the "Questions & feedback".</p>
                                        </Col>
                                    </Row>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className='px-2 pb-3'>
                            <StepProgress>
                                <ProgressBarSample currentStep={currentStepSample} />
                            </StepProgress>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                            <h3>Delivery address</h3>
                        </Stack>
                    </Accordion.Header>
                    <Accordion.Body>
                        <Row className='px-2 pb-2'>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-2" controlId="address1.control">
                                        <Form.Label className={styles.listItemLabel}>Address 1</Form.Label>
                                        <Form.Control type="text" value={address1} placeholder="Street name" disabled={true} />
                                    </Form.Group>
                                </Col>
                                <Col md={5}>
                                    <Form.Group className="mb-2" controlId="address2.control">
                                        <Form.Label className={styles.listItemLabel}>Address 2 (optional)</Form.Label>
                                        <Form.Control type="text" value={address2} placeholder="Suite, unit, etc." disabled={true} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>
                                    <Form.Group className="mb-2" controlId="city.control">
                                        <Form.Label className={styles.listItemLabel}>City</Form.Label>
                                        <Form.Control type="text" value={city} disabled={true} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-2" controlId="state.control">
                                        <Form.Label className={styles.listItemLabel}>State</Form.Label>
                                        <Form.Control type="text" value={state} disabled={true} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-2" controlId="zip.control">
                                        <Form.Label className={styles.listItemLabel}>Zip</Form.Label>
                                        <Form.Control type="text" value={zip} disabled={true} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br />
            <Card className='px-2'>
                <Card.Title className='px-2 pt-3 pb-2'>
                    <Image src={iconMessage} />
                    <span className='ps-3'>Questions & feedback</span>
                </Card.Title>
                <Card.Body className='pt-2'>
                    <Messages
                        title={"Please submit any comments or questions."}
                        buttonLabel={"Submit"}
                        quoteId={quoteData?.id}
                        type={constants.TYPE_MSG_QUOTE_SAMPLE_NON_DECO_FEEDBACK}
                        numberOfLines={3}
                    //disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED).length === 0 ? true : false} 
                    />
                </Card.Body>
            </Card>
            <br />
            <Row className="px-2 py-2 pb-4">
                <Col md={{ span: 4, offset: 4 }}>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible
                            className="mb-2">
                            {errorMsg}
                        </Alert>
                    )}
                    {msg && (
                        <Alert
                            variant="success"
                            onClose={() => setMsg("")}
                            dismissible
                            className="mb-2">
                            {msg}
                        </Alert>
                    )}
                    <div className="d-grid gap-2">
                        <Button className={styles.button + " py-2"} size="sm" ref={sampleSentBtnRef}
                            onClick={() => setShowSampleSentModal(true)}
                            disabled={engagedQuoteStatusHistory?.length > 0 && engagedQuoteStatusHistory[0]?.status === constants?.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED ? false : true}>
                            Send sample
                        </Button>
                    </div>
                </Col>
            </Row>
            <Modal show={showSampleSentModal}
                size="xl"
                fullscreen={false}
                centered
                onHide={() => setShowSampleSentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Non deco sample request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormSampleSent
                        type={"nonDeco"}
                        quoteId={quoteData?.id}
                        sendToParentSampleSent={handleSampleSent} />
                </Modal.Body>
            </Modal>
        </Container>
    )
}