import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth"
import useWindowDimensions from "./useWindowDimensions";
import { useState } from "react";
import { SideNavBar } from "./SideNavBar";
import { NavigationBar } from "./NavigationBar";

export const PageLayout = (props) => {
    const [user, loading] = useAuthState(auth);

    const collapsedWidth = 70;
    const expandedWidth = 250;
    const { height, width } = useWindowDimensions();
    const [sideBarWidth, setSideBarWidth] = useState(expandedWidth);

    const handleCollapseChanged = (e) => {
        setSideBarWidth(sideBarWidth === expandedWidth ? collapsedWidth : expandedWidth);
    }

    return (
        <>
        {(user !== null) ? (
            <div style={{ display: 'flex' }} id={'pageLayoutDiv'}>
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: sideBarWidth,
                        height: height,
                        }}
                    id={'pageLayoutSideNavBarDiv'}>
                    <SideNavBar sendToParentCollapseChanged={handleCollapseChanged} collapsedWidth={collapsedWidth} />
                </div>
                <div
                    style={{
                        position: 'relative',
                        left: sideBarWidth,
                        width: width - sideBarWidth,
                        height: '100%',
                        }}
                    id={'pageLayoutChildrenDiv'}>
                    {props.children}
                </div>
            </div>
        ) : (
            <>
            <NavigationBar />
            {props.children}
            </>
        )}
        </>
    );
}